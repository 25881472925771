import { useEffect } from 'react';

import Navbar from '../layout/Navbar'
import EachUpload from './eachUpload'

export default function Upload(props) {

  useEffect(() => {
    document.title = 'D.search ｜ ユーザー管理';
  }, []);
 

  return (
    <div>
      <Navbar {...props}>
        {/* <EachUpload fileType='dcf' /> */}
        <EachUpload fileType='sdc' />
        <EachUpload fileType='ims' />
      </Navbar>
    </div>
  )
}