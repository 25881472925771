import axios from 'axios'

import React, { useState, useEffect } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { 
  DataGridPro,
  LicenseInfo,
  jaJP,
  GridToolbarContainer,
  GridToolbarFilterButton,
  selectedIdsLookupSelector
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import UserEdit from './UserEdit.js'
import UserDelete from './UserDelete.js'

import Navbar from '../../components/layout/Navbar'
import StandardBox from '../../components/common/StandardBox'
import HeadLine1 from '../../components/common/HeadLine1'

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE,
);

const theme = createTheme(
  jaJP,
);

export default function User(props) {

	const columns = [
		{
		  field: 'id',
		  hide: true,
		},
		{
			field: 'email',
			headerName: 'メールアドレス',
			width: 300,
    },
		{
			field: 'last_name',
			headerName: '名字',
			width: 130,
		},
		{
			field: 'first_name',
			headerName: '名前',
			width: 130,
		},
		{
			field: 'employee_code',
			headerName: '社員コード',
			width: 130,
		},
		{
			field: 'role',
			headerName: '種別',
			width: 130,
		},
    {
      field: 'modify',
      headerName: '',
      renderCell: (params) =>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleOnClickModify(e, params)}
          // disabled={true}
        >
          変更
        </Button>
    },
    {
      field: 'password',
      headerName: '',
      width: 170,
      renderCell: (params) =>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleOnClickPassword(e, params)}
          // disabled={true}
        >
          パスワード変更
        </Button>
    },
    {
      field: 'delete',
      headerName: '',
      renderCell: (params) =>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => deleteRow(e, params)}
        >
          削除
        </Button>
    },
  ];

  const [alertStatus, setAlertStatus] = useState({
    open: false,
    type: "success",
    message: ""
  });

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [userRecord, setUserRecord] = React.useState({});
  const [editDialogMode, setEditDialogMode] = React.useState('');

  const [gridRows, setGridRows] = useState([]);

  useEffect(() => {
    document.title = 'D.search ｜ ユーザー管理';
  }, []);


  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASEURL;
    const getURL = `${apiURL}/user/list`;

    const rows = new Array(0);

    axios({
      method: "GET",
      url:getURL,
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    })
      .then(

        response => {
          // setBackdropOpen(false)
          // setCount(response.data.count);
          // console.log(response.data.rows);

          response.data.rows.forEach((res) => {

            // let authority
            // if (res['authority'] === '1') {
            //   authority = '管理者'
            // } else if (res['authority'] === '2') {
            //   authority = '一般'
            // } else {
            //   authority = '不明'
            // }

            rows.push({
              id : res['id'],
              email : res['email'],
              last_name : res['last_name'],
              first_name : res['first_name'],
              role: res['role'],
              employee_code: res['employee_code'],
            });
          });

          // console.log(rows)
          setGridRows(rows);
          // console.log("set gridRows");

        }
      );

  //     axios.get(getURL)
  //     .then(
  //       response => {
  //         setBackdropOpen(false)
  //         setCount(response.data.count);

  //         response.data.rows.forEach((res) => {
        
  //           rows.push({
  //             id : res['id'],
  //             doctor_name : res['doctor_name'],
  //             doctor_name_kana : res['doctor_name_kana'],
  //             formal_name : res['formal_name'],
  //             hospital_detail : [res['formal_name'], '/dcf_detail/' + res['workplace_institution_code']],
  //             department_name : res['department_name'],
  //             country_name : res['country_name'],
  //             address_name : res['address_name'],
  //             personal_code : res['personal_code'],
  //             workplace_institution_code : res['workplace_institution_code'].replace(',', ', '),
  //             m_association_name : res['m_association_name'].replace(',', ', '),
  //           });
  //         });
          
  //         setGridRows(rows);

  //       }).catch((error) => {
  //         setBackdropOpen(false)
  //         console.log('axios error')

  //         if (error.response) {
  //           setAlertStatus({
  //             open: true,
  //             type: "error",
  //             message: `接続エラー(${error.response})`
  //           });
  //         } else {
  //           setAlertStatus({
  //             open: true,
  //             type: "error",
  //             message: `エラー(${error})`
  //           });
  //         }
  //       }
  //     )
  // }

    }, [editDialogOpen, deleteDialogOpen])

  const deleteRow = (e, params) => {

    setUserRecord(params.row);
    setDeleteDialogOpen(true);
    console.log(params);
  }

  const handleOnClickButton = (param) => {
    console.log('clicked');

    setUserRecord({});
    setEditDialogMode('register')
    setEditDialogOpen(true);
  }
  
  const handleOnClickModify = (e, params) => {
    console.log(params.row);

    // setUserRecord({});
    setUserRecord(params.row);
    setEditDialogMode('modify')
    setEditDialogOpen(true);

  }

  const handleOnClickPassword = (e, params) => {
    console.log(params);

    // setUserRecord({});
    setUserRecord(params.row);
    setEditDialogMode('password')
    setEditDialogOpen(true);

  }

  const handleEditDialogClose = (value) => {

    console.log(value);

    let alertMessage;

    if (value === 'success') {
      if (editDialogMode === 'register') {
        alertMessage = 'ユーザー情報が登録されました';
      } else {
        alertMessage = 'ユーザー情報が変更されました';
      }

      setAlertStatus({
        open: true,
        type: "success",
        // message: `接続エラー(${error.response})`
        message: alertMessage
      });
    }


    // setAlertStatus({
    //   open: true,
    //   type: "error",
    //   // message: `接続エラー(${error.response})`
    //   message: `IDもしくはパスワードをご確認ください`
    // });

    setEditDialogOpen(false);
    // setSelectedValue(value);
  };

  const handleDeleteDialogClose = (value) => {

    setDeleteDialogOpen(false);
  };


  return(
    <Box>
      <Navbar {...props}>
        <StandardBox>
          <HeadLine1>
            ユーザー管理
          </HeadLine1>
        </StandardBox>        

        <StandardBox>
          <Button
            variant="contained"
            onClick={handleOnClickButton}
            size="large"
          >
            ユーザー登録
          </Button>
        </StandardBox>        

        {alertStatus.open &&
          <Alert
            onClose={() => {
              setAlertStatus({
                open: false,
                type: "close",
                message: ""
              });
            }}
            severity={alertStatus.type}>{alertStatus.message}
          </Alert>
        }


        <ThemeProvider theme={theme}>
          <DataGridPro
            autoHeight
            rows={gridRows}
            columns={columns}
            // density='compact'
            pagination
            pageSize={50}
            disableSelectionOnClick={true}
            // onRowClick={(param) => handleOnClickColumn(param.row)}
          />
        </ThemeProvider>

        <UserEdit
          userRecord={userRecord}
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          dialogMode={editDialogMode}
        />

        <UserDelete
          userRecord={userRecord}
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
        />

      </Navbar>


    </Box>
  )
}