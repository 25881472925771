import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import StandardButton from '../common/StandardButton.js'

const CustomedButtun = styled(StandardButton)({
  backgroundColor: "#afafaf"
});


export default function TopMenuBottun(props) {
  return(
    <CustomedButtun
      {...props}
    >
      {props.children}
    </CustomedButtun>
  )
}
