import React, { useState, useEffect } from 'react';

import { useForm, Controller } from "react-hook-form";
import axios from 'axios'

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export default function UserDelete(props) {

  const { onClose,  open, userRecord } = props

  // console.log(userRecord);
  // console.log(userRecord.last_name);

  const handleClose = () => {
    onClose('');
  };

  const handleOnClickButton = () => {

    const apiURL = process.env.REACT_APP_BASEURL;
    const deleteURL = `${apiURL}/user/delete/${userRecord.id}`;

  //   axios.delete('/user', {data: {userId: 'xxxx'}}).then(res => {        
  //     console.log(res.data);     
  //  })

    axios.delete(deleteURL, { data: userRecord.id })
    .then(res => {
      // レスポンスが200の時の処理

      console.log(res.data);
      onClose();

    })
    .catch(error => {
      if(error.response) {
        console.log(error.response)
      } else {
        console.log('その他エラー')
      }
    });


    onClose('');
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>「{userRecord.last_name} {userRecord.first_name}」を削除しますか</DialogTitle>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={handleOnClickButton}
            size="large"
          >
            削除
          </Button>
        </Stack>
      </Box>
    </Dialog>
  )
}