import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const CustomedBox = styled(Box)({
  marginTop: 5
});

export default function BoxAroundTextField(props) {
  return(
    <CustomedBox>{props.children}</CustomedBox>
  )
}