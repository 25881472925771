import axios from 'axios'

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  LicenseInfo,
  jaJP,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE,
);

const theme = createTheme(
  jaJP,
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HospitalSummary(props) {

  const { onClose, selectedHospital, open, token } = props;

  const handleClose = () => {
    onClose('');
  };

  const handleHospitalOnClick = (formal_name) => {
    onClose(formal_name);
  }

  const detailColumns = [
    // {
    //   field: 'id',
    //   hide: true,
    // },
    {
      field: 'title',
      headerName: '項目名',
      width: 200,
      headerClassName: 'column--header',
      cellClassName: 'row--header',
    },
    {
      field: 'data',
      headerName: '内容',
      width: 600,
      headerClassName: 'row--header',
    },
  ];

  const doctorColumns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: '医師名',
      width: 200,
      headerClassName: 'column--header',
      cellClassName: 'row--header',
    },
    {
      field: 'kana',
      headerName: 'カナ',
      width: 200,
      headerClassName: 'column--header',
    },
    {
      field: 'department',
      headerName: '診療科',
      width: 400,
      headerClassName: 'column--header',
    },
  ];

  const hpgpColumns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'title',
      headerName: '項目名',
      width: 200,
      headerClassName: 'column--header',
      cellClassName: 'row--header',
    },
    {
      field: 'data',
      headerName: '売上',
      width: 600,
      type: 'number',
      headerClassName: 'column--header',
    },
  ];

  let savedHospitalTab = Number(localStorage.getItem("savedHospitalTab"))
  if (savedHospitalTab === null) {
    savedHospitalTab = 0
  }

  const [detailRows, setDetailRows] = useState([]);
  const [doctorRows, setDoctorRows] = useState([]);
  const [hpgpRows, setHpgpRows] = useState([]);
  const [hospitalName, setHospitalName] = useState('');

  const [tabValue, setTabValue] = useState(savedHospitalTab);

  const handleTabChange = (event, newValue) => {
    localStorage.setItem('savedHospitalTab', newValue)

    setTabValue(newValue);
  };

  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASEURL;
    const getURL = `${apiURL}/data_send/dcf/hospital/${selectedHospital}`;

    axios({
      method: "GET",
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {

          console.log(response);
          setDetailRows(response.data.detail);
          setHospitalName(response.data.detail[1].data)
          setDoctorRows(response.data.doctor);
          setHpgpRows(response.data.hpgp);
        }
      );

  }, [selectedHospital])

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            {hospitalName}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider', 
          ml: 15,
          mr: 15,
          mt: 4,
          width: 805
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          centered
        >
          <Tab label={(<Typography variant="h6">病院詳細</Typography>)} {...a11yProps(0)} />
          <Tab label={(<Typography variant="h6">所属医師</Typography>)} {...a11yProps(1)} />
          {/* <Tab label={(<Typography variant="h6">病院売上</Typography>)} {...a11yProps(2)} /> */}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Box
            sx={{
              width: 800,
              mt: 4,
              '& .column--header': {
                backgroundColor: '#ededec',
              },
              '& .row--header': {
                backgroundColor: '#ededec',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGridPro
                autoHeight
                rows={detailRows}
                columns={detailColumns}
                hideFooter
                disableSelectionOnClick={true}
              />
            </ThemeProvider>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box
            sx={{
              width: 800,
              mt: 4,
              '& .column--header': {
                backgroundColor: '#ededec',
              },
              '& .row--header': {
                backgroundColor: '#ededec',
              }
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGridPro
                autoHeight
                rows={doctorRows}
                columns={doctorColumns}
                hideFooter
                disableSelectionOnClick={true}
                sx={{
                  mt: 4
                }}
              />
            </ThemeProvider>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Box
            sx={{
              width: 800,
              // width: '100%',
              mt: 4,
              '& .column--header': {
                backgroundColor: '#ededec',
              },
              '& .row--header': {
                backgroundColor: '#ededec',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGridPro
                autoHeight
                rows={hpgpRows}
                columns={hpgpColumns}
                hideFooter
                disableSelectionOnClick={true}
                sx={{
                  mt: 4
                }}
              />
            </ThemeProvider>
          </Box>
        </TabPanel>
      </Box>

    </Dialog>
  )
}

HospitalSummary.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedHospital: PropTypes.string.isRequired,
};
