import axios from 'axios'

import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { 
  DataGridPro,
  LicenseInfo,
  jaJP,
  GridToolbarContainer,
  GridToolbarFilterButton,
  selectedIdsLookupSelector
} from '@mui/x-data-grid-pro';

import Navbar from '../layout/Navbar'
import DcfSdcEdit from './DcfSdcEdit'
import StandardBox from '../../components/common/StandardBox'
import HeadLine1 from '../../components/common/HeadLine1'

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE,
);

const theme = createTheme(
  jaJP,
);


export default function DcfSdc(props) {

	const columns = [
		{
		  field: 'id',
		  hide: true,
		},
		{
			field: 'customer_code',
			headerName: 'SDCコード',
			width: 130,
    },
		{
			field: 'sdc_name',
			headerName: 'SDC施設名',
			width: 300,
		},
		{
			field: 'workplace_institution_code',
			headerName: 'DCFコード',
			width: 130,
		},
		{
			field: 'formal_name',
			headerName: 'DCF施設名',
			width: 300,
		},
    {
      field: 'modify',
      headerName: '',
      renderCell: (params) =>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleOnClickModify(e, params)}
        >
          編集
        </Button>
    },
    {
      field: 'delete',
      headerName: '',
      renderCell: (params) =>
        <Button
          variant="contained"
          color="primary"
          disabled="true"
          // onClick={(e) => deleteRow(e, params)}
        >
          削除
        </Button>
    },
  ];

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [institutionRecord, setInstitutionRecord] = React.useState({});

  const [gridRows, setGridRows] = useState([]);

  useEffect(() => {
    document.title = 'D.search ｜ DCF SDC リンク管理';
  }, []);
 
  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASEURL;
    const getURL = `${apiURL}/admin/sdc_dcf_list`;

    const rows = new Array(0);

    axios({
      method: "GET",
      url:getURL,
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    })
      .then(

        response => {

          response.data.rows.forEach((res) => {

            rows.push({
              id : res['id'],
              customer_code : res['customer_code'],
              sdc_name : res['sdc_name'],
              workplace_institution_code : res['workplace_institution_code'],
              formal_name: res['formal_name'],
            });
          });

          console.log(rows)
          setGridRows(rows);

        }
      );
    }, [props.token, editDialogOpen])

    const handleOnClickModify = (e, params) => {
      console.log(params.row);
  
      if(params.row.workplace_institution_code === null) {
        setInstitutionRecord(params.row);
        setEditDialogOpen(true);
      }
    }

    const handleEditDialogClose = (value) => {

      console.log(value);
  
      let alertMessage;
  
      // if (value === 'success') {
      //   if (editDialogMode === 'register') {
      //     alertMessage = 'ユーザー情報が登録されました';
      //   } else {
      //     alertMessage = 'ユーザー情報が変更されました';
      //   }
  
      //   setAlertStatus({
      //     open: true,
      //     type: "success",
      //     // message: `接続エラー(${error.response})`
      //     message: alertMessage
      //   });
      // }
  
      setEditDialogOpen(false);
    };
        
  return (
    <Box>
      <Navbar {...props}>
        <StandardBox>
          <HeadLine1>
            DCFとSDCの施設紐づけ
          </HeadLine1>
        </StandardBox>

        <ThemeProvider theme={theme}>
          <DataGridPro
            autoHeight
            rows={gridRows}
            columns={columns}
            density='compact'
            pagination
            pageSize={20}
            disableSelectionOnClick={true}
          // onRowClick={(param) => handleOnClickColumn(param.row)}
          />
        </ThemeProvider>

        <DcfSdcEdit
          institutionRecord={institutionRecord}
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          token={props.token}
        />

      </Navbar>
    </Box>
  )
}