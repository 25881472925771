import axios from 'axios'
import fileDownload from 'js-file-download'
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

import jwt_decode from 'jwt-decode';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  LicenseInfo,
  jaJP,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import Alert from '@mui/material/Alert';

import Navbar from '../layout/Navbar'
import Summary from './Summary'

import HeadLine1 from '../common/HeadLine1.js'
import HeadLine2 from '../common/HeadLine2.js'
import PeriodText from '../common/PeriodText.js'
import StandardBox from '../common/StandardBox.js'
import CountBox from '../common/CountBox.js'
import ButtonStack from '../common/ButtonStack.js'
import SearchTextField from '../common/SearchTextField.js'
import BoxAroundTextField from '../common/BoxAroundTextField.js'
import MyDateRangePicker from '../common/MyDateRangePicker.js'
import SelectPrefecture from '../common/SelectPrefecture.js'
import CustomToolbar from '../common/CustomToolbar.js'
import StandardButton from '../common/StandardButton.js'
import ClearButton from '../common/ClearButton.js'
import getPrefectures from '../utility/getPrefectures.js'
// import Analyze from './Analyze';

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE,
);

const theme = createTheme(
  jaJP,
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Agency(props) {

  const { token } = props;

  console.log(jwt_decode(token).role);
  console.log(jwt_decode(token).employee_code);

  let columns;

  columns = [
    {
      field: 'id',
      hide: true,
    },
    // {
    //   field: 'sales_date',
    //   headerName: '伝票年月日',
    //   width: 130,
    // },
    {
      field: 'prefecture',
      headerName: '都道府県',
      width: 100,
    },
    {
      field: 'customer',
      headerName: '得意先名',
      width: 250,
    },
    {
      field: 'workplace_institution_code',
      headerName: 'DCF施設コード',
      width: 120,
    },
    {
      field: 'formal_name',
      headerName: 'DCF施設名',
      width: 250,
    },
    {
      field: 'customer_code',
      headerName: '得意先コード',
      width: 250,
      hide: true
    },
    // {
    //   field: 'product_code',
    //   headerName: '統一商品コード',
    //   width: 130,
    // },
    {
      field: 'product_name',
      headerName: '商品名',
      width: 200,
    },
    // {
    //   field: 'lot',
    //   headerName: 'ロットNo.',
    //   width: 100,
    // },
    {
      field: 'sales_number',
      headerName: '販売数',
      width: 80,
      type: 'number'
    },
    {
      field: 'employee_name',
      headerName: '担当者',
      width: 120,
    },
    {
      field: 'employee_code',
      headerName: '社員コード',
      width: 120,
    },
    // {
    //   field: 'expiry_date',
    //   headerName: '期限',
    //   width: 130,
    // },
    // {
    //   field: 'deal_type',
    //   headerName: '売上区分',
    //   width: 130,
    // },
  ];

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    open: false,
    type: "success",
    message: ""
  });

  let savedStartDateHpgp = localStorage.getItem("savedStartDateAgency")
  if (savedStartDateHpgp == null) {
    savedStartDateHpgp = ''
  }
  let savedEndDateHpgp = localStorage.getItem("savedEndDateAgency")
  if (savedEndDateHpgp == null) {
    savedEndDateHpgp = ''
  }

  let savedProductCodeHpgp = localStorage.getItem("savedProductCodeHpgp")
  if (savedProductCodeHpgp == null) {
    savedProductCodeHpgp = ''
  }
  let savedLotHpgp = localStorage.getItem("savedLotHpgp")
  if (savedLotHpgp == null) {
    savedLotHpgp = ''
  }
  let savedDcfName = localStorage.getItem("savedDcfName")
  if (savedDcfName == null) {
    savedDcfName = ''
  }
  let savedCustomerHpgp = localStorage.getItem("savedCustomerHpgp")
  if (savedCustomerHpgp == null) {
    savedCustomerHpgp = ''
  }
  let savedProductHpgp = localStorage.getItem("savedProductHpgp")
  if (savedProductHpgp == null) {
    savedProductHpgp = ''
  }
  let savedMinBeds = localStorage.getItem("savedMinBeds")
  if (savedMinBeds == null) {
    savedMinBeds = ''
  }
  let savedMaxBeds = localStorage.getItem("savedMaxBeds")
  if (savedMaxBeds == null) {
    savedMaxBeds = ''
  }
  let savedPrefectures = localStorage.getItem("savedPrefectures")
  if (savedPrefectures == null) {
    savedPrefectures = []
  } else {
    savedPrefectures = localStorage.getItem("savedPrefectures").split(',')
  }

  const [count, setCount] = React.useState();
  const [selectedPrefectures, setSelectedPrefectures] = React.useState(savedPrefectures);
  const [startDate, setStartDate] = React.useState(dayjs(savedStartDateHpgp));
  const [endDate, setEndDate] = React.useState(dayjs(savedEndDateHpgp));
  const [startDataDate, setStartDataDate] = React.useState(new Date());
  const [endDataDate, setEndDataDate] = React.useState(new Date());
  const [startEnabledDate, setStartEnabledDate] = React.useState(new Date());
  const [endEnabledDate, setEndEnabledDate] = React.useState(new Date());
  const [customer, setCustomer] = React.useState(savedCustomerHpgp);
  const [product, setProduct] = React.useState(savedProductHpgp);
  const [productCode, setProductCode] = React.useState(savedProductCodeHpgp);
  const [dcfName, setDcfName] = React.useState(savedDcfName);
  const [employeeCode, setEmployeeCode] = React.useState(jwt_decode(token).employee_code);
  const [lot, setLot] = React.useState(savedLotHpgp);
  const [prefectureOpen, setPrefectureOpen] = React.useState(false);
  const [summaryOpen, setSummaryOpen] = React.useState(false);
  const [summaryCustomerName, setSummaryCustomerName] = React.useState('');
  const [summaryCustomerCode, setSummaryCustomerCode] = React.useState('');
  const [minBeds, setMinBeds] = React.useState(savedMinBeds);
  const [maxBeds, setMaxBeds] = React.useState(savedMaxBeds);
  const [employeeList, setEmployeeList] = React.useState([]);

  const [pageSize, setPageSize] = React.useState(10);
  const [gridRows, setGridRows] = useState([]);



  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handlePrefecturesChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    if (value[0] === '') {
      value.shift();
    }

    // console.log(value)

    localStorage.setItem('savedPrefectures', value);

    setSelectedPrefectures(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleCustomerChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)
    localStorage.setItem('savedCustomerHpgp', value);

    setCustomer(value);
  };

  const handleDcfNameChange = (event) => {
    const {
      target: { value },
    } = event;

    localStorage.setItem('savedDcfName', value);

    // console.log(value)

    setDcfName(value);
  };

  const handleProductChange = (event) => {
    const {
      target: { value },
    } = event;

    localStorage.setItem('savedProductHpgp', value);

    // console.log(value)

    setProduct(value);
  };

  const handleProductCodeChange = (event) => {
    const {
      target: { value },
    } = event;

    localStorage.setItem('savedProductCodeHpgp', value);
    // console.log(value)

    setProductCode(value);
  };

  const handleLotNumberChange = (event) => {
    const {
      target: { value },
    } = event;

    localStorage.setItem('savedLotHpgp', value);

    // console.log(value)

    setLot(value);
  };

  const handleMinBedsChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedMinBeds', value);

    // console.log(value)

    setMinBeds(value);
  };

  const handleMaxBedsChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedMaxBeds', value);

    // console.log(value)

    setMaxBeds(value);
  };

  const handleEmployeeCode = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value);
    setEmployeeCode(value);
  };


  const handlePrefectureClose = (prefectures) => {
    setPrefectureOpen(false);

    if (prefectures.length !== 0) {
      setSelectedPrefectures(
        typeof prefectures === 'string' ? prefectures.split(',') : prefectures,
      );
      localStorage.setItem('savedPrefectures', prefectures);
    }
  };

  const handleRowClick = (params) => {
    console.log(summaryCustomerName);
    setSummaryCustomerName(params.customer)
    setSummaryCustomerCode(params.customer_code)
    setSummaryOpen(true);
  }  

  const handleAnalyzeClose = () => {
    setSummaryOpen(false);
    setSummaryCustomerName('');
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_COMPANY);
    if(process.env.REACT_APP_COMPANY === 'amicus') {
      document.title = 'D.search ｜ SDCデータ検索';
    } else {
      document.title = 'D.search ｜ 施設別実績検索';
    }
  }, []);


  useEffect(() => {
    
    const apiURL = process.env.REACT_APP_BASEURL;
    const employeeListURL = `${apiURL}/data_send/employee_list`;

    let lists = []

    axios({
      method: "GET",
      url:employeeListURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    // axios.get(employeeListURL)
      .then(
        response => {

          response.data.rows.forEach((res) => {

            console.log(res);
            lists.push({
              employeeCode: res['employee_code'],
              employeeName: res['last_name'] + res['first_name'],
            })
          });

          setEmployeeList(lists);
        }
      );

  }, [token])


  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASEURL;
    const periodGetURL = `${apiURL}/data_send/agency_sales_period`;

    axios({
      method: "GET",
      url:periodGetURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    // axios.get(periodGetURL)
      .then(
        response => {

          console.log(response);

          const tmpStartDate = new Date(response.data.min_date)
          const tmpEndDate = new Date(response.data.max_date)

          setStartDataDate(tmpStartDate)
          setEndDataDate(tmpEndDate)

          setStartEnabledDate(tmpStartDate)
          setEndEnabledDate(tmpEndDate)
        }
      );

  }, [])



  const clearCondition = () => {
    console.log('clear condition clicked');
    setProductCode('');
    setLot('');
    setSelectedPrefectures([]);
    setCustomer('');
    setDcfName('');
    setProduct('');
    console.log(startDataDate);
    // setStartDate(new Date());
    // setEndDate(new Date());
    // setEndDate(endDataDate);
    setGridRows([]);
    setCount(0);

    // localStorage.setItem('savedStartDateHpgp', '');
    // localStorage.setItem('savedEndDateHpgp', '');
    localStorage.setItem('savedPrefectures', []);
    localStorage.setItem('savedProductCodeHpgp', '');
    localStorage.setItem('savedLotHpgp', '');
    localStorage.setItem('savedCustomerHpgp', '');
    localStorage.setItem('savedProductHpgp', '');
    // localStorage.setItem('savedSociety', '');
  }


  const getData = () => {

    setAlertStatus({
      open: false,
      type: "close",
      message: ""
    });

    let prefectureParam = ''

    selectedPrefectures.forEach((pref) => {
      (
        // console.log(pref)
        prefectureParam = prefectureParam + pref + ','
      )
    })

    prefectureParam = prefectureParam.slice(0, -1)

    // console.log(startDate.format('YYYYMM'))

    if (jwt_decode(token).role === '一般ユーザー' && jwt_decode(token).employee_code !== null) {
      setEmployeeCode(jwt_decode(token).employee_code);
    }

    const apiURL = process.env.REACT_APP_BASEURL;
    const getURL = `${apiURL}/data_send/agency_sales?prefecture=${prefectureParam}&customer_name=${customer}&product_name=${product}&product_code=${productCode}&lot_number=${lot}&start_date=${startDate.format('YYYYMMDD')}&end_date=${endDate.format('YYYYMMDD')}&employee_code=${employeeCode}&dcf_name=${dcfName}`;

    const rows = new Array(0);

    setBackdropOpen(true);

    axios({
      method: "GET",
      url:getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {

          setBackdropOpen(false)

          setCount(response.data.count);

          console.log(response);
          response.data.rows.forEach((res) => {

            let employee_name;
            if (res['last_name'] === null && res['first_name'] === null) {
              employee_name = ''
            } else {
              employee_name = res['last_name'] + res['first_name']
            }
            rows.push({
              id: res['id'],
              sales_date: res['sales_date'],
              customer: res['customer_name'],
              customer_code: res['customer_code'],
              product_name: res['product_name'],
              product_code: res['product_code'],
              sales_number: res['sales_number'],
              lot: res['lot_number'],
              expiry_date: res['expiry_date'],
              deal_type: res['deal_type'],
              employee_code: res['employee_code'],
              employee_name: employee_name,
              workplace_institution_code: res['workplace_institution_code'],
              formal_name: res['formal_name'],
              prefecture: res['prefecture'],
            });
          });
          setGridRows(rows);
        }).catch((error) => {
          setBackdropOpen(false)
          console.log('axios error');
          console.log(error);

          if (error.response) {
            setAlertStatus({
              open: true,
              type: "error",
              message: `接続エラー(${error.response.status})`
            });
          } else {
            setAlertStatus({
              open: true,
              type: "error",
              message: `エラー(${error})`
            });
          }
        }
        );

  }

  const Download = (downloadType) => {

    setBackdropOpen(true);

    let prefectureParam = ''

    selectedPrefectures.forEach((pref) => {
      (
        prefectureParam = prefectureParam + pref + ','
      )
    })

    prefectureParam = prefectureParam.slice(0, -1)


    const apiURL = process.env.REACT_APP_BASEURL;
    // const getURL = `${apiURL}/data_send/dcf/${downloadType}_download?prefecture=${prefectureParam}&doctor=${doctor}&doctor_code=${doctorCode}&hospital=${hospital}&department=${department}&society=${society}&specialty=${specialty}&min_beds=${minBeds}&max_beds=${maxBeds}&alma_mater=${almaMater}`;
    // const getURL = `${apiURL}/data_send/agency/${downloadType}_download?prefecture=${prefectureParam}${urlParameter}`;
    const getURL = `${apiURL}/data_send/agency_sales/excel_donwload?prefecture=${prefectureParam}&customer_name=${customer}&product_name=${product}&product_code=${productCode}&lot_number=${lot}&start_date=${startDate.format('YYYYMMDD')}&end_date=${endDate.format('YYYYMMDD')}&employee_code=${employeeCode}&dcf_name=${dcfName}`;

    console.log(getURL);

    axios({
      method: "GET",
      responseType: 'blob',
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    // axios.get(getURL, {
    //   responseType: 'blob',
    //   Authorization: 'Bearer ' + token
    //   // headers: {
    //   // }
    // })
      .then((res) => {
        console.log(res)
        const now = new Date();
        console.log('dsearch_' + now.toLocaleString("ja"))
        let fileName;
        if (downloadType === 'csv') {
          fileName = 'dsearch_sdc_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.csv';
        } else if (downloadType === 'excel') {
          fileName = 'dsearch_sdc_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.xlsx';
        }
        fileDownload(res.data, fileName);
        setBackdropOpen(false);
      })
  }

  const excelDownload = () => {
    Download('excel');
  }

  const dailyDownload = (downloadType) => {

    setBackdropOpen(true);

    let prefectureParam = ''

    selectedPrefectures.forEach((pref) => {
      (
        prefectureParam = prefectureParam + pref + ','
      )
    })

    prefectureParam = prefectureParam.slice(0, -1)

    const apiURL = process.env.REACT_APP_BASEURL;
    // const getURL = `${apiURL}/data_send/dcf/${downloadType}_download?prefecture=${prefectureParam}&doctor=${doctor}&doctor_code=${doctorCode}&hospital=${hospital}&department=${department}&society=${society}&specialty=${specialty}&min_beds=${minBeds}&max_beds=${maxBeds}&alma_mater=${almaMater}`;
    // const getURL = `${apiURL}/data_send/agency/${downloadType}_download?prefecture=${prefectureParam}${urlParameter}`;
    const getURL = `${apiURL}/data_send/agency_sales_daily_excel?prefecture=${prefectureParam}&customer_name=${customer}&product_name=${product}&product_code=${productCode}&lot_number=${lot}&start_date=${startDate.format('YYYYMMDD')}&end_date=${endDate.format('YYYYMMDD')}&employee_code=${employeeCode}&dcf_name=${dcfName}`;

    console.log(getURL);

    axios({
      method: "GET",
      responseType: 'blob',
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    // axios.get(getURL, {
    //   responseType: 'blob',
    //   Authorization: 'Bearer ' + token
    //   // headers: {
    //   // }
    // })
      .then((res) => {
        console.log(res)
        const now = new Date();
        console.log('dsearch_' + now.toLocaleString("ja"))
        let fileName;
        if (downloadType === 'csv') {
          fileName = 'dsearch_sdc_dayly_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.csv';
        } else if (downloadType === 'excel') {
          fileName = 'dsearch_sdc_daily_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.xlsx';
        }
        fileDownload(res.data, fileName);
        setBackdropOpen(false);
      })
  }

  const dailyExcelDownload = () => {
    dailyDownload('excel');
  }

  return (
    <Box>
      <Navbar {...props}>
        {alertStatus.open &&
          <Alert
            onClose={() => {
              setAlertStatus({
                open: false,
                type: "close",
                message: ""
              });
            }}
            severity={alertStatus.type}>{alertStatus.message}</Alert>
        }

        <StandardBox>
          {(process.env.REACT_APP_COMPANY === 'amicus') &&
            <HeadLine1>
            SDCデータ検索
            </HeadLine1>
          }
          {(process.env.REACT_APP_COMPANY === 'sparks') &&
            <HeadLine1>
            施設別実績検索
            </HeadLine1>
          }
        </StandardBox>

        {(jwt_decode(token).role !== '一般ユーザー') &&
          <StandardBox>
            <HeadLine2>
              ■ 担当者
              {/* ■ 期間 */}
            </HeadLine2>

            <FormControl
              sx={{
                width: 200,
                backgroundColor: "#FFFFFF",
                // mb: 2
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">担当者</InputLabel>
              <Select
                labelId="prefecture"
                id="prefecture"
                label="都道府県名"
                value={employeeCode}
                onChange={handleEmployeeCode}
              // input={<OutlinedInput label="Tag" />}
              >
                <MenuItem key={''} value={''}>選択なし</MenuItem>
                {employeeList.map((employee) => (
                  <MenuItem key={employee['employeeCode']} value={employee['employeeCode']}>
                    {employee['employeeName']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </StandardBox>
        }
  
        <StandardBox>
          <Stack
            sx={{
              // pt:1,
              pb: 2
            }}
          >
            <HeadLine2>
              ■ 期間
              {/* ■ 期間 */}
            </HeadLine2>

            <PeriodText>
              {/* {startDataDate.getFullYear()} */}
              {startDataDate.getFullYear()}年{startDataDate.getMonth() + 1}月{startDataDate.getDate()}日 - {endDataDate.getFullYear()}年{endDataDate.getMonth() + 1}月{endDataDate.getDate()}日 集計済み
            </PeriodText>
          </Stack>

          <BoxAroundTextField>

            <MyDateRangePicker
              value={startDate}
              // minDate={new Date("2021-01-01")}
              minDate={startEnabledDate}
              maxDate={endEnabledDate}
              onChange={(newValue) => {
                localStorage.setItem('savedStartDateAgency', newValue);
                setStartDate(newValue);
              }}
            />
            ～
            <MyDateRangePicker
              value={endDate}
              minDate={startEnabledDate}
              maxDate={endEnabledDate}
              onChange={(newValue) => {
                localStorage.setItem('savedEndDateAgency', newValue);
                setEndDate(newValue);
              }}
            />

          </BoxAroundTextField>

        </StandardBox>

        <StandardBox>
          <HeadLine2>
            ■ ワード
          </HeadLine2>
          <BoxAroundTextField>
            <SearchTextField
              id="customer"
              label="得意先名"
              value={customer}
              onChange={handleCustomerChange}
            />
            <SearchTextField
              id="dcf"
              label="処方元医療施設名"
              value={dcfName}
              onChange={handleDcfNameChange}
            />
            <SearchTextField
              id="product"
              label="商品名"
              value={product}
              onChange={handleProductChange}
            />
            <SearchTextField
              id="productCode"
              label="統一商品コード"
              onChange={handleProductCodeChange}
              value={productCode}
            />
            <SearchTextField
              id="lot"
              label="ロットNo."
              value={lot}
              onChange={handleLotNumberChange}
            />
          </BoxAroundTextField>
        </StandardBox>

        {(process.env.REACT_APP_COMPANY === 'sparks') &&
          <>
            <StandardBox>
              <HeadLine2>
                ■ 病床数から探す
              </HeadLine2>
              <BoxAroundTextField>
                <SearchTextField
                  id="doctor"
                  label="最小数"
                  value={minBeds}
                  type="number"
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={handleMinBedsChange}
                />
                ～
                <SearchTextField
                  id="doctorid"
                  label="最大数"
                  value={maxBeds}
                  type="number"
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={handleMaxBedsChange}
                />
              </BoxAroundTextField>
            </StandardBox>
          </>
        }

        <StandardBox>
          <HeadLine2>
            ■ 地域から探す
          </HeadLine2>

          <BoxAroundTextField>

            <StandardButton
              onClick={() => {
                setPrefectureOpen(true);
              }}
              sx={{
                mt: 1,
              }}
            >
              地図から選択する
            </StandardButton>

            <FormControl
              sx={{
                width: 300,
                backgroundColor: "#FFFFFF",
                ml: 1
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">都道府県</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedPrefectures}
                onChange={handlePrefecturesChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {getPrefectures().map((name) => (
                  <MenuItem key={name['jis_country_code']} value={name['country_name']}>
                    <Checkbox checked={selectedPrefectures.indexOf(name['country_name']) > -1} />
                    <ListItemText primary={name['country_name']} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </BoxAroundTextField>
        </StandardBox>

        <StandardBox>
          <ButtonStack>
            <StandardButton onClick={getData}>検索</StandardButton>
            <ClearButton onClick={clearCondition}>条件クリア</ClearButton>
          </ButtonStack>
        </StandardBox>

        <CountBox>
          売上期間：{startDate.format('YYYY年MM月DD日')} ～ {endDate.format('YYYY年MM月DD日')}
        </CountBox>

        <CountBox>
          検索件数：{count}　検索結果全件ダウンロード　<Button variant="outlined" onClick={excelDownload}>Excel</Button> 　日毎データダウンロード　<Button variant="outlined" onClick={dailyExcelDownload}>Excel</Button>
        </CountBox>

        <ThemeProvider theme={theme}>
          <DataGridPro
            autoHeight
            rows={gridRows}
            columns={columns}
            density='compact'
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick={true}
            onRowClick={(param) => handleRowClick(param.row)}
            // density='compact'
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            // initialState={{ pinnedColumns: { left: ['brick_name'] } }}
          // componentsProps={{
          //   panel: {
          //     anchorEl: filterButtonEl,
          //   },
          //   toolbar: {
          //     setFilterButtonEl,
          //   },
          // }}
          // sortModel={sortModel}
          // onSortModelChange={(model) => setSortModel(model)}
          />
        </ThemeProvider>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
          onClick={handleBackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

      </Navbar>


      {summaryOpen &&
        <Summary
          selectedCustomer={summaryCustomerName}
          selectedCustomerCode={summaryCustomerCode}
          open={summaryOpen}
          onClose={handleAnalyzeClose}
          startDate={startDate}
          endDate={endDate}
          token={token}
        />
      }

      <SelectPrefecture
        setSelectedPrefectures={setSelectedPrefectures}
        selectedPrefectures={selectedPrefectures}
        open={prefectureOpen}
        onClose={handlePrefectureClose}
      />

    </Box>
  )
}