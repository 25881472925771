import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CustomedButtun = styled(Button)({
});


export default function TopMenuBottun(props) {
  return(
    <CustomedButtun
      {...props}
      variant="contained"
      size="large"
    >
      {props.children}
    </CustomedButtun>
  )
}
