import React, { useEffect } from 'react';

import jwt_decode from 'jwt-decode';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Navbar from '../../components/layout/Navbar'
import TopMenuButton from '../../components/common/TopMenuButton';
import logo from '../../assets/images/logo.png';

export default function Top(props) {

  const { token } = props;

  console.log(process.env.REACT_APP_BASEURL);
  console.log(process.env.REACT_APP_MENU_ORDER);

  useEffect(() => {
    document.title = 'D.search ｜ メインメニュー';
  }, []);

  return (
    <Box>
      <Navbar {...props}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Stack
            spacing={3}
            justifyContent="center"
            alignItems='center'
          >
            <img src={`${process.env.PUBLIC_URL}${process.env.REACT_APP_LOGOURL}`} alt="Logo"/>

            <Typography component="h1" variant="h4" color="primary" fontWeight='bold'>
              メインメニュー
            </Typography>

            {(process.env.REACT_APP_COMPANY === 'amicus') &&
              <>
                <TopMenuButton
                  to="/hpgp"
                >
                  IMSデータ検索
                </TopMenuButton>

                <TopMenuButton
                  to="/agency"
                >
                  SDCデータ検索
                </TopMenuButton>

                <TopMenuButton
                  to="/dcf"
                >
                  アルトマーク医師検索
                </TopMenuButton>
              </>
            }

            {(process.env.REACT_APP_COMPANY === 'sparks') &&
              <>
                <TopMenuButton
                  to="/dcf"
                >
                  医師検索
                </TopMenuButton>

                <TopMenuButton
                  to="/hpgp"
                >
                  病院売上検索
                </TopMenuButton>
              </>
            }

            <Divider />

            {(jwt_decode(token).role === '管理者') &&
              <>
                <TopMenuButton
                  to="/hpgp"
                  disabled={true}
                >
                  データ更新
                </TopMenuButton>

                <TopMenuButton
                  to="/user"
                >
                  ユーザー管理
                </TopMenuButton>
              </>
            }

 
          </Stack>



        </Box>
        {/* {(process.env.REACT_APP_COMPANY === 'sparks') &&
          <>
            <Box sx={{textAlign: "right", m: 4}}>
              事業再構築
            </Box>
          </>
        } */}
      </Navbar>
    </Box>
  )
}