import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const CustomedBox = styled(Box)({
  marginBottom: 8,
});

export default function CountBox(props) {
  return(
    <CustomedBox>{props.children}</CustomedBox>
  )
}