import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const CustomedButtun = styled(Button)({
  mt: 3,
  mb: 2,
  width: 396,
});

const CustomedTypography = styled(Typography)({
  color: 'white'
});


export default function TopMenuBottun(props) {
  return(
    <CustomedButtun
      {...props}
      variant="contained"
      size="large"
      component={Link}
    >
      <CustomedTypography>
        {props.children}
      </CustomedTypography>
    </CustomedButtun>
  )
}