import React, { useState, useEffect } from 'react';

import axios from 'axios'
import fileDownload from 'js-file-download'

import jwt_decode from 'jwt-decode';

// import { Link, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  LicenseInfo,
  jaJP,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Navbar from '../../components/layout/Navbar'
import DoctorSummary from './DoctorSummary.js'
import HospitalSummary from './HospitalSummary.js'

import HeadLine1 from '../common/HeadLine1.js'
import HeadLine2 from '../common/HeadLine2.js'
import StandardBox from '../common/StandardBox.js'
import CountBox from '../common/CountBox.js'
import ButtonStack from '../common/ButtonStack.js'
import SearchTextField from '../common/SearchTextField.js'
import BoxAroundTextField from '../common/BoxAroundTextField.js'
import SelectPrefecture from '../common/SelectPrefecture.js'
import CustomToolbar from '../common/CustomToolbar.js'
import StandardButton from '../common/StandardButton.js'
import ClearButton from '../common/ClearButton.js'
import getPrefectures from '../utility/getPrefectures.js'

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE,
);

const theme = createTheme(
  jaJP,
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Dcf(props) {

  const { token } = props;

  // console.log(props.token);
  // console.log(props);

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'doctor_name',
      headerName: '医師名',
      width: 130,
    },
    {
      field: 'personal_code',
      headerName: '医師コード',
      width: 130,
    },
    {
      field: 'formal_name',
      headerName: '病院正式名',
      width: 400,
    },
    {
      field: 'workplace_institution_code',
      headerName: '施設コード',
      width: 130,
    },
    {
      field: 'max_beds',
      headerName: '病床数',
      width: 150,
      type: 'number'
    },
    {
      field: 'department_name',
      headerName: '診療科',
      width: 200,
    },
    {
      field: 'department_code',
      headerName: '診療科コード',
      width: 120,
    },
    {
      field: 'classification_code',
      headerName: '診療分野分類',
      width: 120,
    },
    {
      field: 'specialty_name',
      headerName: '専門医',
      width: 200,
    },
    {
      field: 'm_association_name',
      headerName: '学会',
      width: 200,
    },
    {
      field: 'country_name',
      headerName: '都道府県',
      width: 130,
      hide: true,
    },
    {
      field: 'address_name',
      headerName: '住所',
      width: 400,
    },
    {
      field: 'alma_mater_name',
      headerName: '出身校',
      width: 150,
    },
  ];
  // const ref = useRef(true);

  // console.log(process.env.REACT_APP_COMPANY);
  // console.log(columns);

  if (process.env.REACT_APP_COMPANY !== 'sparks') {

    for(let i = 0; columns[i] !== undefined;i ++) {
      if (columns[i]['field'] === "specialty_name" ||  columns[i]['field'] === "max_beds") {
        columns[i]["hide"] = true;
      }
    }

  }

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    open: false,
    type: "success",
    message: ""
  });

  const [selectedDoctor, setSelectedDoctor] = React.useState({});
  const [doctorOpen, setDoctorOpen] = React.useState(false);
  const [instutitionCode, setInstutitionCode] = React.useState('');
  const [hospitalOpen, setHospitalOpen] = React.useState(false);
  const [prefectureOpen, setPrefectureOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);

  let savedDoctor = localStorage.getItem("savedDoctor")
  if (savedDoctor == null) {
    savedDoctor = ''
  }
  let savedAlmaMater = localStorage.getItem("savedAlmaMater")
  if (savedAlmaMater == null) {
    savedAlmaMater = ''
  }
  let savedHospital = localStorage.getItem("savedHospital")
  if (savedHospital == null) {
    savedHospital = ''
  }
  let savedDepartment = localStorage.getItem("savedDepartment")
  if (savedDepartment == null) {
    savedDepartment = ''
  }
  let savedSociety = localStorage.getItem("savedSociety")
  if (savedSociety == null) {
    savedSociety = ''
  }
  let savedSpecialty = localStorage.getItem("savedSpecialty")
  if (savedSpecialty == null) {
    savedSpecialty = ''
  }
  let savedDoctorCode = localStorage.getItem("savedDoctorCode")
  if (savedDoctorCode == null) {
    savedDoctorCode = ''
  }
  let savedWorkplaceInstitutionCode = localStorage.getItem("savedWorkplaceInstitutionCode")
  if (savedWorkplaceInstitutionCode == null) {
    savedWorkplaceInstitutionCode = ''
  }
  let savedDepartmentCode = localStorage.getItem("savedDepartmentCode")
  if (savedDepartmentCode == null) {
    savedDepartmentCode = ''
  }
  let savedClassificationCode = localStorage.getItem("savedClassificationCode")
  if (savedClassificationCode == null) {
    savedClassificationCode = ''
  }
  let savedMinBeds = localStorage.getItem("savedMinBeds")
  if (savedMinBeds == null) {
    savedMinBeds = ''
  }
  let savedMaxBeds = localStorage.getItem("savedMaxBeds")
  if (savedMaxBeds == null) {
    savedMaxBeds = ''
  }
  let savedPrefectures = localStorage.getItem("savedPrefectures")
  if (savedPrefectures == null) {
    savedPrefectures = []
  } else {
    // console.log(savedPrefectures);
    // console.log(typeof(savedPrefectures));
    savedPrefectures = localStorage.getItem("savedPrefectures").split(',')
  }

  const [gridRows, setGridRows] = useState([]);
  const [pageSize, setPageSize] = React.useState(50);

  const [count, setCount] = React.useState();
  const [selectedPrefectures, setSelectedPrefectures] = React.useState(savedPrefectures);
  const [doctor, setDoctor] = React.useState(savedDoctor);
  const [almaMater, setAlmaMater] = React.useState(savedAlmaMater);
  const [hospital, setHospital] = React.useState(savedHospital);
  const [department, setDepartment] = React.useState(savedDepartment);
  const [society, setSociety] = React.useState(savedSociety);
  const [specialty, setSpecialty] = React.useState(savedSpecialty);
  const [doctorCode, setDoctorCode] = React.useState(savedDoctorCode);
  const [workplaceInstitutionCode, setWorkplaceInstitutionCode] = React.useState(savedWorkplaceInstitutionCode);
  const [classificationCode, setClassificationCode] = React.useState(savedClassificationCode);
  const [departmentCode, setDepartmentCode] = React.useState(savedDepartmentCode);
  const [minBeds, setMinBeds] = React.useState(savedMinBeds);
  const [maxBeds, setMaxBeds] = React.useState(savedMaxBeds);
  // const [urlParameter, setUrlParameter] = React.useState(`&doctor=${doctor}&doctor_code=${doctorCode}&hospital=${hospital}&department=${department}&society=${society}&specialty=${specialty}&min_beds=${minBeds}&max_beds=${maxBeds}&alma_mater=${almaMater}`);

  const urlParameter = `&doctor=${doctor}&doctor_code=${doctorCode}&hospital=${hospital}&department=${department}&society=${society}&specialty=${specialty}&min_beds=${minBeds}&max_beds=${maxBeds}&alma_mater=${almaMater}&workplace_institution_code=${workplaceInstitutionCode}&department_code=${departmentCode}&classification_code=${classificationCode}`;

  useEffect(() => {
    document.title = 'D.search ｜ 医師検索';
  }, []);

  useEffect(() => {
    console.log(process.env.REACT_APP_COMPANY);
    if(process.env.REACT_APP_COMPANY === 'amicus') {
      document.title = 'D.search ｜ アルトマーク医師検索';
    } else {
      document.title = 'D.search ｜ 医師検索';
    }
  }, []);


  const handlePrefecturesChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value)

    localStorage.setItem('savedPrefectures', value);

    setSelectedPrefectures(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleDoctorChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    localStorage.setItem('savedDoctor', value);

    setDoctor(value);
  };

  const handleAlmaMaterChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    localStorage.setItem('savedAlmaMater', value);

    setAlmaMater(value);
  };

  const handleHospitalChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)
    localStorage.setItem('savedHospital', value);

    setHospital(value);
  };

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)
    localStorage.setItem('savedDepartment', value);

    setDepartment(value);
  };

  const handleSocietyChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedSociety', value);

    // console.log(value)

    setSociety(value);
  };

  const handleSpecialtyChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedSpecialty', value);

    // console.log(value)

    setSpecialty(value);
  };

  const handlesWorkplaceInstitutionCodeChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    localStorage.setItem('savedWorkplaceInstitutionCode', value);

    setWorkplaceInstitutionCode(value);
  };

  const handleDepartmentCodeChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    localStorage.setItem('savedDepartmentCode', value);

    setDepartmentCode(value);
  };

  const handleDoctorCodeChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    localStorage.setItem('savedDoctorCode', value);

    setDoctorCode(value);
  };

  const handleClassificationCodeChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    localStorage.setItem('savedClassificationCode', value);

    setClassificationCode(value);
  };

  const handleMinBedsChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedMinBeds', value);

    // console.log(value)

    setMinBeds(value);
  };

  const handleMaxBedsChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedMaxBeds', value);

    // console.log(value)

    setMaxBeds(value);
  };



  const getData = () => {

    setAlertStatus({
      open: false,
      type: "close",
      message: ""
    });

    let prefectureParam = ''

    selectedPrefectures.forEach((pref) => {
      (
        prefectureParam = prefectureParam + pref + ','
      )
    })

    prefectureParam = prefectureParam.slice(0, -1)

    console.log(prefectureParam);

    console.log(maxBeds);

    if (minBeds > maxBeds && minBeds !== '' && maxBeds !== '') {
      handleAlertOpen();
    }

    const apiURL = process.env.REACT_APP_BASEURL;
    // const getURL = `${apiURL}/data_send/dcf?prefecture=${prefectureParam}&doctor=${doctor}&doctor_code=${doctorCode}&hospital=${hospital}&department=${department}&society=${society}&specialty=${specialty}&min_beds=${minBeds}&max_beds=${maxBeds}&alma_mater=${almaMater}`;
    const getURL = `${apiURL}/data_send/dcf?prefecture=${prefectureParam}${urlParameter}`;

    const rows = new Array(0);

    setAlertStatus({
      open: false,
      type: "close",
      message: ""
    });

    setBackdropOpen(true);

    axios({
      method: "GET",
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {
          setBackdropOpen(false)
          setCount(response.data.count);

          console.log(response.data)

          response.data.rows.forEach((res) => {

            rows.push({
              id: res['id'],
              doctor_name: res['doctor_name'],
              doctor_name_kana: res['doctor_name_kana'],
              formal_name: res['formal_name'],
              hospital_detail: [res['formal_name'], '/dcf_detail/' + res['workplace_institution_code']],
              department_name: res['department_name'],
              country_name: res['country_name'],
              address_name: res['address_name'],
              personal_code: res['personal_code'],
              workplace_institution_code: res['workplace_institution_code'].replace(',', ', '),
              m_association_name: res['m_association_name'].replace(',', ', '),
              // specialty_name: res['specialty_name'].replace(',', ', '),
              specialty_name: res['specialty_name'],
              max_beds: res['max_beds'],
              alma_mater_name: res['alma_mater_name'],
              department_code: res['department_code'],
              classification_code: res['classification_code'],
            });
          });

          // console.log(rows);
          setGridRows(rows);

        }).catch((error) => {
          setBackdropOpen(false)
          console.log('axios error')

          if (error.response) {
            setAlertStatus({
              open: true,
              type: "error",
              message: `接続エラー(${error.response})`
            });
          } else {
            setAlertStatus({
              open: true,
              type: "error",
              message: `エラー(${error})`
            });
          }
        }
        )
  }

  const clearCondition = () => {
    console.log('clear condition clicked');
    setDoctor('');
    setDoctorCode('');
    setSelectedPrefectures([]);
    setHospital('');
    setDepartment('');
    setSociety('');
    setSpecialty('');
    setAlmaMater('')
    setWorkplaceInstitutionCode('')
    setDepartmentCode('')
    setClassificationCode('')
    setGridRows([]);
    setCount(0);

    localStorage.setItem('savedPrefectures', []);
    localStorage.setItem('savedDoctor', '');
    localStorage.setItem('savedDoctorCode', '');
    localStorage.setItem('savedHospital', '');
    localStorage.setItem('savedDepartment', '');
    localStorage.setItem('savedSociety', '');
    localStorage.setItem('savedSpecialty', '');
    localStorage.setItem('savedAlmaMater', '');
    localStorage.setItem('savedWorkplaceInstitutionCode', '');
    localStorage.setItem('savedClassificationCode', '');
    localStorage.setItem('savedSpecialty', '');
  }

  const handleDoctorClose = (workplace_institution_code) => {
    setDoctorOpen(false);

    if (workplace_institution_code !== '') {
      setInstutitionCode(workplace_institution_code);
      setHospitalOpen(true);
    }
    else {
      setInstutitionCode('');
    }
  };


  const handleHospitalClose = () => {
    setHospitalOpen(false);
    setSelectedDoctor({});
    setInstutitionCode('')
  };

  const handlePrefectureClose = (prefectures) => {
    setPrefectureOpen(false);

    if (prefectures.length !== 0) {
      setSelectedPrefectures(
        typeof prefectures === 'string' ? prefectures.split(',') : prefectures,
      );
      localStorage.setItem('savedPrefectures', prefectures);
    }
  };

  const handleOnClick = (param) => {
    console.log(param.workplace_institution_code);

    setSelectedDoctor(param.personal_code);
    setDoctorOpen(true);

  }

  const Download = (downloadType) => {

    setBackdropOpen(true);

    let prefectureParam = ''

    selectedPrefectures.forEach((pref) => {
      (
        prefectureParam = prefectureParam + pref + ','
      )
    })

    prefectureParam = prefectureParam.slice(0, -1)


    const apiURL = process.env.REACT_APP_BASEURL;
    // const getURL = `${apiURL}/data_send/dcf/${downloadType}_download?prefecture=${prefectureParam}&doctor=${doctor}&doctor_code=${doctorCode}&hospital=${hospital}&department=${department}&society=${society}&specialty=${specialty}&min_beds=${minBeds}&max_beds=${maxBeds}&alma_mater=${almaMater}`;
    const getURL = `${apiURL}/data_send/dcf/${downloadType}_download?prefecture=${prefectureParam}${urlParameter}`;

    console.log(getURL);


    axios({
      method: "GET",
      responseType: 'blob',
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    // axios.get(getURL, {
    //   responseType: 'blob',
    //   Authorization: 'Bearer ' + token
    //   // headers: {
    //   // }
    // })
      .then((res) => {
        console.log(res)
        const now = new Date();
        console.log('dsearch_' + now.toLocaleString("ja"))
        let fileName;
        if (downloadType === 'csv') {
          fileName = 'dsearch_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.csv';
        } else if (downloadType === 'excel') {
          fileName = 'dsearch_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.xlsx';
        }
        fileDownload(res.data, fileName);
        setBackdropOpen(false);
      })
  }

  const csvDownload = () => {

    Download('csv');

  }

  const excelDownload = () => {

    Download('excel');

  }

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box>
      {/* <Navbar removeToken={props.removeToken}> */}
      <Navbar {...props}>
        {alertStatus.open &&
          <Alert
            onClose={() => {
              setAlertStatus({
                open: false,
                type: "close",
                message: ""
              });
            }}
            severity={alertStatus.type}>{alertStatus.message}
          </Alert>
        }
        <StandardBox>
          {(process.env.REACT_APP_COMPANY === 'amicus') &&
            <HeadLine1>
            アルトマーク医師検索
            </HeadLine1>
          }
          {(process.env.REACT_APP_COMPANY === 'sparks') &&
            <HeadLine1>
            医師検索
            </HeadLine1>
          }
        </StandardBox>

        <StandardBox>
          <HeadLine2>
            ■ ワードから探す
          </HeadLine2>
          <BoxAroundTextField>
            <SearchTextField
              id="doctor"
              label="医師名"
              value={doctor}
              onChange={handleDoctorChange}
            />
            <SearchTextField
              id="almamater"
              label="出身校"
              value={almaMater}
              onChange={handleAlmaMaterChange}
            />
            {(process.env.REACT_APP_COMPANY === 'sparks') &&
              <>
                <SearchTextField
                  id="specialty"
                  label="専門医"
                  value={specialty}
                  onChange={handleSpecialtyChange}
                />
              </>
            }
          </BoxAroundTextField>
          <BoxAroundTextField>
            <SearchTextField
              id="hospital"
              label="施設名"
              value={hospital}
              onChange={handleHospitalChange}
            />
            <SearchTextField
              id="department"
              label="診療科"
              value={department}
              onChange={handleDepartmentChange}
            />
            <SearchTextField
              id="society"
              label="学会"
              value={society}
              onChange={handleSocietyChange}
            />
          </BoxAroundTextField>
          {/* <BoxAroundTextField>
            退職checkbox            
          </BoxAroundTextField> */}
        </StandardBox>

        {(process.env.REACT_APP_COMPANY === 'sparks') &&
          <>
            <StandardBox>
              <HeadLine2>
                ■ 病床数から探す
              </HeadLine2>
              <BoxAroundTextField>
                <SearchTextField
                  id="doctor"
                  label="最小数"
                  value={minBeds}
                  type="number"
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={handleMinBedsChange}
                />
                ～
                <SearchTextField
                  id="doctorid"
                  label="最大数"
                  value={maxBeds}
                  type="number"
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={handleMaxBedsChange}
                />
              </BoxAroundTextField>
            </StandardBox>
          </>
        }

        <StandardBox>
          <HeadLine2>
            ■ コードから探す
          </HeadLine2>
          <BoxAroundTextField>
            <SearchTextField
              id="doctorid"
              label="医師コード"
              value={doctorCode}
              onChange={handleDoctorCodeChange}
            />
            <SearchTextField
              id="workplaceinstitutioncode"
              label="施設コード"
              value={workplaceInstitutionCode}
              onChange={handlesWorkplaceInstitutionCodeChange}
            />
            <SearchTextField
              id="almamater"
              label="診療科目コード"
              value={departmentCode}
              onChange={handleDepartmentCodeChange}
            />
          </BoxAroundTextField>
          <BoxAroundTextField>
            <SearchTextField
              id="classificationcode"
              label="診療分野分類"
              value={classificationCode}
              onChange={handleClassificationCodeChange}
            />
          </BoxAroundTextField>
        </StandardBox>

        <StandardBox>
          <HeadLine2>
            ■ 地域から探す
          </HeadLine2>

          <BoxAroundTextField>

            <StandardButton
              onClick={() => {
                setPrefectureOpen(true);
              }}
              sx={{
                mt: 1,
              }}
            >
              地図から選択する
            </StandardButton>

            <FormControl
              sx={{
                width: 300,
                backgroundColor: "#FFFFFF",
                // mt: 1,
                ml: 1
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">都道府県</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedPrefectures}
                onChange={handlePrefecturesChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {getPrefectures().map((name) => (
                  <MenuItem key={name['jis_country_code']} value={name['country_name']}>
                    <Checkbox checked={selectedPrefectures.indexOf(name['country_name']) > -1} />
                    <ListItemText primary={name['country_name']} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </BoxAroundTextField>
        </StandardBox>

        <StandardBox>
          <ButtonStack>
            <StandardButton onClick={getData}>検索</StandardButton>
            <ClearButton onClick={clearCondition}>条件クリア</ClearButton>
          </ButtonStack>
        </StandardBox>

        <CountBox>
          検索件数：{count}  
          {((jwt_decode(token).role !== '一般ユーザー') || process.env.REACT_APP_COMPANY === 'sparks') &&
            <>
              　検索結果全件ダウンロード　<Button variant="outlined" onClick={csvDownload}>CSV</Button> <Button variant="outlined" onClick={excelDownload}>Excel</Button>
            </>    }        
          </CountBox>

        <ThemeProvider theme={theme}>
          <DataGridPro
            autoHeight
            rows={gridRows}
            columns={columns}
            density='compact'
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pageSize={pageSize}
            disableSelectionOnClick={true}
            onRowClick={(param) => handleOnClick(param.row)}
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            initialState={{ pinnedColumns: { left: ['doctor_name'] } }}
          />
        </ThemeProvider>
      </Navbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {doctorOpen &&
        <DoctorSummary
          personal_code={selectedDoctor}
          open={doctorOpen}
          onClose={handleDoctorClose}
          token={token}
        />
      }

      {hospitalOpen &&
        <HospitalSummary
          selectedHospital={instutitionCode}
          open={hospitalOpen}
          onClose={handleHospitalClose}
          token={token}
        />
      }

      <SelectPrefecture
        setSelectedPrefectures={setSelectedPrefectures}
        selectedPrefectures={selectedPrefectures}
        open={prefectureOpen}
        onClose={handlePrefectureClose}
      />

      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          数字入力不整合
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            病床数の最大値が最小値より少ないです
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>      

    </Box>
  )
}