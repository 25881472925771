import { useState } from 'react';

import axios from 'axios';

export default function useToken() {

  console.log('inside useToken');

  function getToken() {
    console.log('get token')

    const userToken = localStorage.getItem('drsearch_token_1');

    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());

  function saveToken(userToken, role) {
    localStorage.setItem('drsearch_token_1', userToken);

    console.log("save token");
    setToken(userToken);
  };

  function removeToken() {

    console.log('remove token')
    localStorage.removeItem("drsearch_token_1");
    setToken(null);
  }

  return {
    setToken: saveToken,
    token,
    removeToken
  }

}

// const apiURL = process.env.REACT_APP_BASEURL;
// const postURL = `${apiURL}/auth/token_confirm`;

// if (userToken !== "" && userToken !== undefined && userToken !== null) {

//   console.log(userToken);

//   axios.post(postURL, {
//     token: userToken,
//   }, {
//     headers: {
//       "Content-Type": "application/json",
//     }
//   })
//   .then(res => {
//     // レスポンスが200の時の処理

//     console.log(res.data);
//   })
//   .catch(error => {
//     if(error.response) {
//       console.log(error.response)
//       // setAlertStatus({
//       //   open: true,
//       //   type: "error",
//       //   // message: `接続エラー(${error.response})`
//       //   message: `IDもしくはパスワードをご確認ください`
//       // });
//     } else {
//       console.log('その他エラー')
//     }
//   });
// }
