const medicineNameTable = {
  "vpriv": "商品A",
  "vimizim": "商品B",
  "cerdelga": "商品C",
  "aldurazyme": "商品D",
  "brazaves": "商品E",
  "cerezyme": "商品F",
  "elaprase": "商品G",
  "fabrazyme": "商品H",
  "myozyme": "商品I",
  "naglazyme": "商品J",
  "replagal_dainippon": "競合商品A",
  "replagal_takeda": "競合商品B",
  "galafold": "競合商品C",
  "agalsida_be_bs_jcr": "競合商品D",
  "agalsida_be_bs_p_s": "競合商品E",
  "izcargo": "競合商品F",
  "hunterase_icv": "競合商品G",
  "nexviazyme": "競合商品H",
};

export default medicineNameTable;