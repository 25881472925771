import { Link } from "react-router-dom";

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SvgIcon from "@mui/material/SvgIcon";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinkIcon from '@mui/icons-material/Link';
import SellIcon from '@mui/icons-material/Sell';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

import { ReactComponent as MenuIcon } from "./../../assets/icons/menu.svg"
import { ReactComponent as HospitalIcon } from "./../../assets/icons/hospital.svg"
import { ReactComponent as DoctorIcon } from "./../../assets/icons/doctor.svg"
import { ReactComponent as UseradminIcon } from "./../../assets/icons/useradmin.svg"
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

// import HospitalIcon from '../../assets/icons/hospital.svg'
// import DoctorIcon from '../../assets/icons/doctor.svg'
// import UseradminIcon from '../../assets/icons/useradmin.svg'

export const mainListItems = (
  <div>

    <ListItemButton component={Link} to="/" >
      <ListItemIcon>
        <SvgIcon>
          <MenuIcon />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="メニュー" />
    </ListItemButton>

    {(process.env.REACT_APP_COMPANY === 'amicus') &&
      <>
        <ListItemButton component={Link} to="/hpgp">
          <ListItemIcon>
            <SvgIcon>
              <DoctorIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="病院売上検索" />
        </ListItemButton>

        <ListItemButton component={Link} to="/agency">
          <ListItemIcon>
            <SvgIcon>
              <PointOfSaleIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="施設別実績検索" />
        </ListItemButton>

        <ListItemButton component={Link} to="/dcf">
          <ListItemIcon>
            <SvgIcon>
              <HospitalIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="医師検索" />
        </ListItemButton>
      </>
    }

    {(process.env.REACT_APP_COMPANY === 'sparks') &&
      <>
        <ListItemButton component={Link} to="/dcf">
          <ListItemIcon>
            <SvgIcon>
              <HospitalIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="医師検索" />
        </ListItemButton>

        <ListItemButton component={Link} to="/hpgp">
          <ListItemIcon>
            <SvgIcon>
              <DoctorIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="病院売上検索" />
        </ListItemButton>
      </>
    }

  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>管理機能</ListSubheader>

    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="データ更新" />
    </ListItemButton>

    <ListItemButton component={Link} to="/user">
      <ListItemIcon>
        <SvgIcon>
          <UseradminIcon />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="ユーザー管理" />
    </ListItemButton>

    <ListItemButton component={Link} to="/dcf_sdc">
      <ListItemIcon>
        <SvgIcon>
          <LinkIcon />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary="SDCとDCFの紐づけ管理" />
    </ListItemButton>
  </div>
);