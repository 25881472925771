export default function getPrefectures() {

  const prefectures = [

    { id: 1, country_name: '北海道', jis_country_code: '01' },
    { id: 2, country_name: '青森県', jis_country_code: '02' },
    { id: 3, country_name: '岩手県', jis_country_code: '03' },
    { id: 4, country_name: '宮城県', jis_country_code: '04' },
    { id: 5, country_name: '秋田県', jis_country_code: '05' },
    { id: 6, country_name: '山形県', jis_country_code: '06' },
    { id: 7, country_name: '福島県', jis_country_code: '07' },
    { id: 8, country_name: '茨城県', jis_country_code: '08' },
    { id: 9, country_name: '栃木県', jis_country_code: '09' },
    { id: 10, country_name: '群馬県', jis_country_code: '10' },
    { id: 11, country_name: '埼玉県', jis_country_code: '11' },
    { id: 12, country_name: '千葉県', jis_country_code: '12' },
    { id: 13, country_name: '東京都', jis_country_code: '13' },
    { id: 14, country_name: '神奈川県', jis_country_code: '14' },
    { id: 15, country_name: '新潟県', jis_country_code: '15' },
    { id: 16, country_name: '富山県', jis_country_code: '16' },
    { id: 17, country_name: '石川県', jis_country_code: '17' },
    { id: 18, country_name: '福井県', jis_country_code: '18' },
    { id: 19, country_name: '山梨県', jis_country_code: '19' },
    { id: 20, country_name: '長野県', jis_country_code: '20' },
    { id: 21, country_name: '岐阜県', jis_country_code: '21' },
    { id: 22, country_name: '静岡県', jis_country_code: '22' },
    { id: 23, country_name: '愛知県', jis_country_code: '23' },
    { id: 24, country_name: '三重県', jis_country_code: '24' },
    { id: 25, country_name: '滋賀県', jis_country_code: '25' },
    { id: 26, country_name: '京都府', jis_country_code: '26' },
    { id: 27, country_name: '大阪府', jis_country_code: '27' },
    { id: 28, country_name: '兵庫県', jis_country_code: '28' },
    { id: 29, country_name: '奈良県', jis_country_code: '29' },
    { id: 30, country_name: '和歌山県', jis_country_code: '30' },
    { id: 31, country_name: '鳥取県', jis_country_code: '31' },
    { id: 32, country_name: '島根県', jis_country_code: '32' },
    { id: 33, country_name: '岡山県', jis_country_code: '33' },
    { id: 34, country_name: '広島県', jis_country_code: '34' },
    { id: 35, country_name: '山口県', jis_country_code: '35' },
    { id: 36, country_name: '徳島県', jis_country_code: '36' },
    { id: 37, country_name: '香川県', jis_country_code: '37' },
    { id: 38, country_name: '愛媛県', jis_country_code: '38' },
    { id: 39, country_name: '高知県', jis_country_code: '39' },
    { id: 40, country_name: '福岡県', jis_country_code: '40' },
    { id: 41, country_name: '佐賀県', jis_country_code: '41' },
    { id: 42, country_name: '長崎県', jis_country_code: '42' },
    { id: 43, country_name: '熊本県', jis_country_code: '43' },
    { id: 44, country_name: '大分県', jis_country_code: '44' },
    { id: 45, country_name: '宮崎県', jis_country_code: '45' },
    { id: 46, country_name: '鹿児島県', jis_country_code: '46' },
    { id: 47, country_name: '沖縄県', jis_country_code: '47' }
  ]

  return (prefectures);
}
