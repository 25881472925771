import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomedTextField = styled(TextField)({
  // marginRight: 8,
  backgroundColor: "#FFFFFF"
});

export default function MyDateRangePicker(props) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ja'} dateFormats={{ monthAndYear: "YYYY年 MM月" }} >
      <DatePicker
        {...props}
        label="伝票年月日"
        // views={['year', 'month']}
        renderInput={(params) =>
          <CustomedTextField
            {...params}
          />
        }
      />
    </LocalizationProvider>
  )
}
