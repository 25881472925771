import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CustomedTypography = styled(Typography)({
  fontSize: 15,
  letterSpacing: ''
});



export default function PeriodText(props) {
  return(
     <CustomedTypography>{props.children}</CustomedTypography>
  )
}