import Navbar from '../../components/layout/Navbar'

export default function error404(props) {
  return(
		<div>
      <Navbar {...props}>
        ページがみつかりません
			</Navbar>
		</div>
	)
}