import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import "./map.css"

export default function SelectPrefecture(props) {

  const { onClose, selectedPrefectures, setSelectedPrefectures, open } = props;


  const handleClose = (prefectures) => {
    onClose(prefectures);
  };

  const getPrefectures = () => {

    let prefectures = '';

    for (let i = 0; i < 47; i ++) {
      let prefectureCheckbox = document.getElementById('area' + String(i+1))

      if(prefectureCheckbox.checked) {
        prefectures += prefectureCheckbox.name + ',';
      }
    }

    prefectures = prefectures.slice(0, -1)

    return(prefectures);
  }


  return (
    // <Dialog onClose={handleClose} open={open}>
    <Dialog
      onClose={() => {
        handleClose('');
      }}
      open={open}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          // width: "50%",
          height: 630
        }
      }}
    >
      {/* <DialogTitle><Typography variant="h4">{doctorName} ({doctorNameKana})</Typography></DialogTitle> */}
      <DialogTitle><Typography variant="h4">県選択</Typography></DialogTitle>

      <Box
        sx={{
          position: 'absolute',
          top: 550,
          m: 1,
          p: 1,
        }}
      >
        <Button
            variant="contained"
            size="large"
          // onClick={handleClose(prefectures)}
          // onClick={() => {
          //   onClose(prefectures);
          // }}
          onClick={() => {
            handleClose(getPrefectures());
          }}
        >
          選択
        </Button>
      </Box>

      <div className="mapbox">
        <div className="map">
          <ul className="map-list">
            <li className="map-item region1 area1"><input type="checkbox" id="area1" name="北海道" value="北海道" /><label htmlFor="area1" className="area-link"><span className="map-item-space">北海道</span></label></li>
            <li className="map-item region2 area2"><input type="checkbox" id="area2" name="青森県" value="青森" /><label htmlFor="area2" className="area-link"><span className="map-item-space">青森</span></label></li>
            <li className="map-item region2 area3"><input type="checkbox" id="area3" name="秋田県" value="秋田" /><label htmlFor="area3" className="area-link"><span className="map-item-space">秋田</span></label></li>
            <li className="map-item region2 area4"><input type="checkbox" id="area4" name="岩手県" value="岩手" /><label htmlFor="area4" className="area-link"><span className="map-item-space">岩手</span></label></li>
            <li className="map-item region2 area5"><input type="checkbox" id="area5" name="山形県" value="山形" /><label htmlFor="area5" className="area-link"><span className="map-item-space">山形</span></label></li>
            <li className="map-item region2 area6"><input type="checkbox" id="area6" name="宮城県" value="宮城" /><label htmlFor="area6" className="area-link"><span className="map-item-space">宮城</span></label></li>
            <li className="map-item region2 area7"><input type="checkbox" id="area7" name="福島県" value="福島" /><label htmlFor="area7" className="area-link"><span className="map-item-space">福島</span></label></li>
            <li className="map-item region3 area12"><input type="checkbox" id="area12" name="東京都" value="東京" /><label htmlFor="area12" className="area-link"><span className="map-item-space">東京</span></label></li>
            <li className="map-item region3 area13"><input type="checkbox" id="area13" name="神奈川県" value="神奈川" /><label htmlFor="area13" className="area-link"><span className="map-item-space">神奈川</span></label></li>
            <li className="map-item region3 area14"><input type="checkbox" id="area14" name="千葉県" value="千葉" /><label htmlFor="area14" className="area-link"><span className="map-item-space">千葉</span></label></li>
            <li className="map-item region3 area10"><input type="checkbox" id="area10" name="埼玉県" value="埼玉" /><label htmlFor="area10" className="area-link"><span className="map-item-space">埼玉</span></label></li>
            <li className="map-item region3 area11"><input type="checkbox" id="area11" name="茨城県" value="茨城" /><label htmlFor="area11" className="area-link"><span className="map-item-space">茨城</span></label></li>
            <li className="map-item region3 area8"><input type="checkbox" id="area8" name="群馬県" value="群馬" /><label htmlFor="area8" className="area-link"><span className="map-item-space">群馬</span></label></li>
            <li className="map-item region3 area9"><input type="checkbox" id="area9" name="栃木県" value="栃木" /><label htmlFor="area9" className="area-link"><span className="map-item-space">栃木</span></label></li>
            <li className="map-item region4 area15"><input type="checkbox" id="area15" name="新潟県" value="新潟" /><label htmlFor="area15" className="area-link"><span className="map-item-space">新潟</span></label></li>
            <li className="map-item region4 area16"><input type="checkbox" id="area16" name="長野県" value="長野" /><label htmlFor="area16" className="area-link"><span className="map-item-space">長野</span></label></li>
            <li className="map-item region4 area17"><input type="checkbox" id="area17" name="山梨県" value="山梨" /><label htmlFor="area17" className="area-link"><span className="map-item-space">山梨</span></label></li>
            <li className="map-item region4 area19"><input type="checkbox" id="area19" name="富山県" value="富山" /><label htmlFor="area19" className="area-link"><span className="map-item-space">富山</span></label></li>
            <li className="map-item region4 area20"><input type="checkbox" id="area20" name="石川県" value="石川" /><label htmlFor="area20" className="area-link"><span className="map-item-space">石川</span></label></li>
            <li className="map-item region4 area21"><input type="checkbox" id="area21" name="福井県" value="福井" /><label htmlFor="area21" className="area-link"><span className="map-item-space">福井</span></label></li>
            <li className="map-item region4 area23"><input type="checkbox" id="area23" name="愛知県" value="愛知" /><label htmlFor="area23" className="area-link"><span className="map-item-space">愛知</span></label></li>
            <li className="map-item region4 area18"><input type="checkbox" id="area18" name="静岡県" value="静岡" /><label htmlFor="area18" className="area-link"><span className="map-item-space">静岡</span></label></li>
            <li className="map-item region4 area22"><input type="checkbox" id="area22" name="岐阜県" value="岐阜" /><label htmlFor="area22" className="area-link"><span className="map-item-space">岐阜</span></label></li>
            <li className="map-item region4 area25"><input type="checkbox" id="area25" name="三重県" value="三重" /><label htmlFor="area25" className="area-link"><span className="map-item-space">三重</span></label></li>
            <li className="map-item region5 area29"><input type="checkbox" id="area29" name="大阪府" value="大阪" /><label htmlFor="area29" className="area-link"><span className="map-item-space">大阪</span></label></li>
            <li className="map-item region5 area26"><input type="checkbox" id="area26" name="京都府" value="京都" /><label htmlFor="area26" className="area-link"><span className="map-item-space">京都</span></label></li>
            <li className="map-item region5 area28"><input type="checkbox" id="area28" name="兵庫県" value="兵庫" /><label htmlFor="area28" className="area-link"><span className="map-item-space">兵庫</span></label></li>
            <li className="map-item region5 area27"><input type="checkbox" id="area27" name="奈良県" value="奈良" /><label htmlFor="area27" className="area-link"><span className="map-item-space">奈良</span></label></li>
            <li className="map-item region5 area24"><input type="checkbox" id="area24" name="滋賀県" value="滋賀" /><label htmlFor="area24" className="area-link"><span className="map-item-space">滋賀</span></label></li>
            <li className="map-item region5 area30"><input type="checkbox" id="area30" name="和歌山県" value="和歌山" /><label htmlFor="area30" className="area-link"><span className="map-item-space">和歌山</span></label></li>
            <li className="map-item region6 area34"><input type="checkbox" id="area34" name="広島県" value="広島" /><label htmlFor="area34" className="area-link"><span className="map-item-space">広島</span></label></li>
            <li className="map-item region6 area33"><input type="checkbox" id="area33" name="岡山県" value="岡山" /><label htmlFor="area33" className="area-link"><span className="map-item-space">岡山</span></label></li>
            <li className="map-item region6 area35"><input type="checkbox" id="area35" name="山口県" value="山口" /><label htmlFor="area35" className="area-link"><span className="map-item-space">山口</span></label></li>
            <li className="map-item region6 area32"><input type="checkbox" id="area32" name="島根県" value="島根" /><label htmlFor="area32" className="area-link"><span className="map-item-space">島根</span></label></li>
            <li className="map-item region6 area31"><input type="checkbox" id="area31" name="鳥取県" value="鳥取" /><label htmlFor="area31" className="area-link"><span className="map-item-space">鳥取</span></label></li>
            <li className="map-item region7 area36"><input type="checkbox" id="area36" name="香川県" value="香川" /><label htmlFor="area36" className="area-link"><span className="map-item-space">香川</span></label></li>
            <li className="map-item region7 area37"><input type="checkbox" id="area37" name="愛媛県" value="愛媛" /><label htmlFor="area37" className="area-link"><span className="map-item-space">愛媛</span></label></li>
            <li className="map-item region7 area38"><input type="checkbox" id="area38" name="高知県" value="高知" /><label htmlFor="area38" className="area-link"><span className="map-item-space">高知</span></label></li>
            <li className="map-item region7 area39"><input type="checkbox" id="area39" name="徳島県" value="徳島" /><label htmlFor="area39" className="area-link"><span className="map-item-space">徳島</span></label></li>
            <li className="map-item region8 area40"><input type="checkbox" id="area40" name="福岡県" value="福岡" /><label htmlFor="area40" className="area-link"><span className="map-item-space">福岡</span></label></li>
            <li className="map-item region8 area43"><input type="checkbox" id="area43" name="佐賀県" value="佐賀" /><label htmlFor="area43" className="area-link"><span className="map-item-space">佐賀</span></label></li>
            <li className="map-item region8 area44"><input type="checkbox" id="area44" name="長崎県" value="長崎" /><label htmlFor="area44" className="area-link"><span className="map-item-space">長崎</span></label></li>
            <li className="map-item region8 area41"><input type="checkbox" id="area41" name="大分県" value="大分" /><label htmlFor="area41" className="area-link"><span className="map-item-space">大分</span></label></li>
            <li className="map-item region8 area42"><input type="checkbox" id="area42" name="熊本県" value="熊本" /><label htmlFor="area42" className="area-link"><span className="map-item-space">熊本</span></label></li>
            <li className="map-item region8 area45"><input type="checkbox" id="area45" name="宮崎県" value="宮崎" /><label htmlFor="area45" className="area-link"><span className="map-item-space">宮崎</span></label></li>
            <li className="map-item region8 area46"><input type="checkbox" id="area46" name="鹿児島県" value="鹿児島" /><label htmlFor="area46" className="area-link"><span className="map-item-space">鹿児島</span></label></li>
            <li className="map-item region9 area47"><input type="checkbox" id="area47" name="沖縄県" value="沖縄" /><label htmlFor="area47" className="area-link"><span className="map-item-space">沖縄</span></label></li>
          </ul>
        </div>  
      </div>
    </Dialog>
  );
}
