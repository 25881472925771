import React, { useState, useEffect } from 'react';

import axios from 'axios'

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export default function UserEdit(props) {

  const { onClose, institutionRecord, open, dialogMode } = props

  console.log(institutionRecord);
  console.log(institutionRecord.workplace_institution_code);
  // console.log(dialogMode);

  let thisDialogTitle = 'SDCコード追加';
  let defaultDisableMode = false;


  const [buttonDeactive, setButtonDeactive] = useState(true);
  const [dcfCode, setDcfCode] = useState(institutionRecord.workplace_institution_code);
  const [dcfName, setDcfName] = useState('');

  const handleRequired = (event) => {
    const {
      target: { value },
      target: { id },
    } = event;

    console.log(id)
    console.log(value)

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiURL = process.env.REACT_APP_BASEURL;
    const putURL = `${apiURL}/admin/sdc_dcf_modify`;

    console.log({
      dcfCode: data.get('dcfCode'),
    });

    axios.put(putURL, {
      dcf_code: data.get('dcfCode'),
      sdc_code: institutionRecord.customer_code,
    }, {
      headers: {
        Authorization: 'Bearer ' + props.token,
        "Content-Type": "application/json",
      }
    })
      .then(res => {
        onClose('success');

        console.log(res.data);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response)
        } else {
          console.log('その他エラー')
        }
      });

  };

  const handleDcfCode = (event) => {
    const {
      target: { value },
    } = event;

    setButtonDeactive(true);

    setDcfCode(value);
    console.log(value);
  };

  const handleClose = () => {
    onClose('cancel');
  };

  const codeConfirm = () => {
    console.log('confirm');

    const apiURL = process.env.REACT_APP_BASEURL;
    const getURL = `${apiURL}/admin/sdc_dcf_confirm?dcf_code=${dcfCode}`;

    axios({
      method: "GET",
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    })
      .then(res => {
        // レスポンスが200の時の処理
        setDcfName(res.data.formal_name);
        setButtonDeactive(false);
        console.log(res.data);
      })
      .catch(error => {
        if (error.response.status === 404) {
          setDcfName('病院なし')
          console.log('病院なし');
        } else {
          console.log('その他エラー')
        }
      }
    );

  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{thisDialogTitle}</DialogTitle>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Stack
          spacing={2}
          sx={{
            m: 2,
            width: '40ch'
          }}
        >
          {institutionRecord.customer_code}
          {institutionRecord.sdc_name}
          <TextField
            // defaultValue={institutionRecord.workplace_institution_code}
            // value={dcfCode}
            required
            id="dcfCode"
            label="DCFコード"
            name="dcfCode"
            onChange={handleDcfCode}
            // disabled={defaultDisableMode}
            autoFocus
          />

          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
          >

            <Button
              size="large"
              variant="contained"
              // disabled={buttonActive}
              onClick={() => {
                codeConfirm();
              }}
            >
              コード確認
            </Button>

          </Stack>

            {dcfName}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
          >

            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={buttonDeactive}
            >
              登録
            </Button>

            <Button
              // type="submit"
              size="large"
              variant="contained"
              onClick={() => {
                onClose();
              }}
            >
              キャンセル
            </Button>
          </Stack>

        </Stack>
      </Box>
    </Dialog>
  )
}