import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import StandardBox from './StandardBox';
import HeadLine2 from './HeadLine2';
import BoxAroundTextField from './BoxAroundTextField';
import StandardButton from './StandardButton';
import getPrefectures from '../utility/getPrefectures.js'


export default function SelectPrefecture2(props) {

  const [prefectureOpen, setPrefectureOpen] = React.useState(false);

  let prefectures = getPrefectures();
  prefectures.unshift(
    { id: 0, country_name: '全国', jis_country_code: '0' },
  )

  const handlePrefectureChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value);

    localStorage.setItem('savedPrefecture', value);

    props.setSelectedPrefecture(value);
  };

  return (
    <StandardBox>
      <HeadLine2>
        ■ 県選択
      </HeadLine2>

      <BoxAroundTextField>

        <StandardButton
          onClick={() => {
            setPrefectureOpen(true);
          }}
          sx={{
            mt: 1,
          }}
        >
          地図から選択する
        </StandardButton>

        <FormControl
          sx={{
            width: 300,
            backgroundColor: "#FFFFFF",
            ml: 1
          }}
        >
          <InputLabel id="demo-simple-select-label">都道府県</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.selectedPrefecture}
            label="Age"
            onChange={handlePrefectureChange}
          >
            {prefectures.map((name) => (
              <MenuItem key={name['jis_country_code']} value={name['country_name']}>
                {name['country_name']}
              </MenuItem>
            ))}

          </Select>
        </FormControl>

      </BoxAroundTextField>
    </StandardBox>
  )
}