import Button from '@mui/material/Button';

import { 
  GridToolbarContainer,
  GridToolbarExport,
  gridSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

export default function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  return (
    <GridToolbarContainer>
      {/* <GridToolbarExport csvOptions={{utf8WithBom: true}}/> */}
      <Button
        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows, utf8WithBom: true })}
      >
        表示分ダウンロード
      </Button>
    </GridToolbarContainer>
  );
}
