import {useState, useEffect} from 'react';

import axios from 'axios';
import jwt_decode from 'jwt-decode';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import logo from '../../assets/images/logo.png'; 

const EmailVaildPattern = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";

const theme = createTheme();

export default function SignIn(props) {

  let buttonStatus = false;

  const [alertStatus, setAlertStatus] = useState({
    open: false,
    type: "success",
    message: ""
  });

  useEffect(() => {
    document.title = 'D.search ｜ ログイン';
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();

    const apiURL = process.env.REACT_APP_BASEURL;
    const postURL = `${apiURL}/auth/token`;

    const data = new FormData(event.currentTarget);


    axios.post(postURL, {
      email: data.get('email'),
      password: data.get('password'),
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then(res => {
      // レスポンスが200の時の処理
      props.setToken(res.data.access_token)
      console.log(res.data);

      console.log(jwt_decode(res.data.access_token));
    })
    .catch(error => {
      if(error.response) {
        console.log(error.response)
        setAlertStatus({
          open: true,
          type: "error",
          // message: `接続エラー(${error.response})`
          message: `IDもしくはパスワードをご確認ください`
        });
      } else {
        console.log('その他エラー')
      }
    });
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >

      <Stack
        spacing={1}
        justifyContent="center"
        alignItems='center'
      >
        {/* <img src={logo} alt="Logo" /> */}
        <img src={`${process.env.PUBLIC_URL}${process.env.REACT_APP_LOGOURL}`} alt="Logo" />

        <Typography component="h1" variant="h4" color="primary" fontWeight='bold'>
          DCF IMS
        </Typography>

        <Typography component="h1" variant="h4" color="primary" fontWeight='bold'>
          検索システム
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            '& > :not(style)': {
            m: 1,
            p: 4,
            width: 396,
            height: 240,
            },
          }}
        >
          <Paper variant="outlined">
            <Stack>
              <TextField
                margin="normal"
                required
                id="email"
                label="Eメール"
                name="email"
                inputProps={ {required: true, pattern: EmailVaildPattern} }
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
              >

                <Button
                  disabled={buttonStatus}
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 3, mb: 2 }}
                >
                  ログイン
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Box>

      </Stack>

      {(process.env.REACT_APP_COMPANY === 'sparks') &&
        <>
          <Box sx={{textAlign: "right", m: 2, width: 450}}>
            事業再構築
          </Box>
        </>
      }

      {alertStatus.open &&
        <Alert
          onClose={() => {
            setAlertStatus({
              open: false,
              type: "close",
              message: ""
            });
          }}
          severity={alertStatus.type}>{alertStatus.message}
        </Alert>
      }


    </Box>
  );
}