import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const CustomedTypography = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 32,
});

export default function HeadLine1(props) {
  return(
    <CustomedTypography>{props.children}</CustomedTypography>
  )
}