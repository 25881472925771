import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

const CustomedStack = styled(Stack)({
  justifyContent: "center",
  backgroundColor: "#eaeaea",
  padding: 12,
});

export default function ButtonStack(props) {
  return(
    <CustomedStack direction="row" spacing={2}>{props.children}</CustomedStack>
  )
}