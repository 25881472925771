import React, { useState, useEffect } from 'react';

import axios from 'axios'

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';


const EmailVaildPattern = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";
// const EmailVaildPattern = "^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
export default function UserEdit(props) {

  const { onClose, userRecord, open, dialogMode } = props

  // console.log(userRecord);
  // console.log(dialogMode);

  let defaultLastName = ''
  let defaultFirstName = ''
  let defaultEmail = ''
  let defaultRole = '一般ユーザー'
  let defaultCode = ''
  let thisDialogTitle = '';
  let defaultDisableMode = false;

  if (dialogMode === 'modify') {
    defaultLastName = userRecord.last_name;
    defaultFirstName = userRecord.first_name;
    defaultEmail = userRecord.email;
    defaultRole = userRecord.role;
    defaultCode = userRecord.employee_code;
  
    console.log(userRecord.role);

    thisDialogTitle = '登録変更';

  } else if (dialogMode === 'register') {
    thisDialogTitle = 'ユーザー登録';
  } else if (dialogMode === 'password') {
    defaultLastName = userRecord.last_name;
    defaultFirstName = userRecord.first_name;
    defaultEmail = userRecord.email;

    defaultDisableMode = true;

    thisDialogTitle = 'パスワード変更';
  }

  console.log(defaultRole);

  const [buttonActive, setButtonActive] = useState(false);
  // const [lastName, setLastName] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState(defaultLastName);
  const [firstName, setFirstName] = useState(defaultFirstName);
  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState('');
  const [disableMode, setDisableMode] = useState(defaultDisableMode);
  const [role, setRole] = useState(defaultRole);

  const handleRequired = (event) => {
    const {
      target: { value },
      target: { id },
    } = event;

    console.log(id)
    console.log(value)

    if (id === 'lastName') {
      setLastName(value);
    } else if (id === 'firstName') {
      setFirstName(value);
    } else if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiURL = process.env.REACT_APP_BASEURL;

    let putURL;

    // console.log(data.get('authorty'));

    if (dialogMode === 'register') {
      putURL = `${apiURL}/user/register`;
    } else if (dialogMode === 'modify' || dialogMode === 'password') {
      putURL = `${apiURL}/user/update`;
    }

    console.log({
      email: data.get('email'),
      password: data.get('password'),
      role: role
    });

    axios.put(putURL, {
      id: userRecord.id,
      first_name: data.get('firstName'),
      last_name: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
      employee_code: data.get('employee_code'),
      role: role,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(res => {
        // レスポンスが200の時の処理
        onClose('success');

        console.log(res.data);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response)
        } else {
          console.log('その他エラー')
        }
      });

  };

  const handleRadioButton = (event) => {
    console.log(event.target.value);
    setRole(event.target.value);
  };


  const handleClose = () => {
    onClose('cancel');
  };

  // console.log(userRecord);
  // console.log(dialogMode);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{thisDialogTitle}</DialogTitle>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Stack
          spacing={2}
          sx={{
            m: 2,
            width: '40ch'
          }}
        >

          <TextField
            required
            defaultValue={defaultLastName}
            id="lastName"
            label="名字"
            name="lastName"
            onChange={handleRequired}
            autoComplete="family-name"
            disabled={defaultDisableMode}
            autoFocus
          />
          <TextField
            autoComplete="given-name"
            defaultValue={defaultFirstName}
            name="firstName"
            required
            id="firstName"
            label="名前"
            onChange={handleRequired}
            disabled={defaultDisableMode}
          />

          <TextField
            inputProps={{ required: true, pattern: EmailVaildPattern }}
            defaultValue={defaultEmail}
            required
            id="email"
            label="Emailアドレス"
            name="email"
            onChange={handleRequired}
            autoComplete="email"
            disabled={defaultDisableMode}
          />

          <TextField
            defaultValue={defaultCode}
            id="employee_code"
            label="社員番号"
            name="employee_code"
            onChange={handleRequired}
            disabled={defaultDisableMode}
          />

          {(props.dialogMode === 'register' || props.dialogMode === 'password') &&

            <TextField
              required
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="new-password"
              onChange={handleRequired}
            />
          }

          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
          >

            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={role}
                onChange={handleRadioButton}
              >
                <FormControlLabel value="マネージャー" control={<Radio />} label="マネージャー" />
                <FormControlLabel value="一般ユーザー" control={<Radio />} label="一般ユーザー" />
                <FormControlLabel value="管理者" control={<Radio />} label="spks管理者" />
              </RadioGroup>
            </FormControl>

          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
          >

            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={buttonActive}
            >
              登録
            </Button>

            <Button
              // type="submit"
              size="large"
              variant="contained"
              onClick={() => {
                onClose();
              }}
            >
              キャンセル
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  )
}