import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { jaJP } from '@mui/material/locale';

import jwt_decode from 'jwt-decode';

import Top from './components/menu/Top'
import Hpgp from './components/hpgp/Hpgp'
import Analyze from './components/hpgp/Analyze'
import Dcf from './components/dcf/Dcf'
import Agency from './components/agency/Agency'
import User from './components/admin/User'
import SignUp from './components/sign/SignUp'
import SignIn from './components/sign/SignIn'
import useToken from './components/token/UseToken'
import Error404 from './components/error/Error404'
import Upload from './components/upload/upload';
import DcfSdc from './components/admin/DcfSdc';

const theme = createTheme(
  jaJP,
);

function App() {
  const { token, removeToken, setToken } = useToken();

  localStorage.removeItem('drsearch_role');

  console.log('token: ' + token)
  // console.log('setToken: ' + setToken)

  return (
    <BrowserRouter>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}> */}
        <ThemeProvider theme={theme}>
          <div>
            {}
            {/* {!token && token !== "" && token !== undefined && !role && role !== "" && role !== undefined ?  */}
            {!token && token !== "" && token !== undefined ? 
              <SignIn setToken={setToken} />
              :(
                <>
                  {(jwt_decode(token).role === '管理者') &&
                    <Routes>
                      <Route path='/' element={<Top token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/hpgp' element={<Hpgp token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/hpgp/analyze' element={<Analyze token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/dcf' element={<Dcf token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/agency' element={<Agency token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/' element={<Dcf token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='*' element={<Error404 token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/user' element={<User token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/upload' element={<Upload token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/dcf_sdc' element={<DcfSdc token={token} setToken={setToken} removeToken={removeToken}/>} />
                    </Routes>
                  }
                  {(jwt_decode(token).role !== '管理者' ) &&
                    <Routes>
                      <Route path='/' element={<Top token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/hpgp' element={<Hpgp token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/hpgp/analyze' element={<Analyze token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/agency' element={<Agency token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/dcf' element={<Dcf token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='/' element={<Dcf token={token} setToken={setToken} removeToken={removeToken}/>} />
                      <Route path='*' element={<Error404 token={token} setToken={setToken} removeToken={removeToken}/>} />
                    </Routes>
                  }
                </>
              )}
          </div>
        </ThemeProvider>
      {/* </LocalizationProvider> */}
    </BrowserRouter>
  );
}

{/* <Routes>
<Route path='/hpgp' element={<Hpgp />} />
<Route path='/dcf' element={<Dcf />} />
<Route path='/' element={<Dcf />} />
<Route path='/signup' element={<SignUp />} />
<Route path='/signin' element={<SignIn />} />
<Route path='*' element={<Error404 />} />
</Routes> */}


// {!token && token !== "" && token !== undefined?  
// <SignIn setToken={setToken} />
// :(
//   <>
//     <Routes>
//       <Route path='/hpgp' element={<Hpgp />} />
//       {/* <Route path='/dcf' element={<Dcf token="aaaa" setToken={setToken} removeToken={removeToken}/>} /> */}
//       <Route path='/dcf' element={<Dcf token={token} setToken={setToken} removeToken={removeToken}/>} />
//       <Route path='/' element={<Dcf token={token} setToken={setToken} removeToken={removeToken}/>} />
//       <Route path='/signup' element={<SignUp />} />
//       {/* <Route path='/signin' element={<SignIn />} /> */}
//       <Route path='*' element={<Error404 />} />
//     </Routes>
//     {/* <Routes>
//       <Route exact path="/profile" element={<Profile token={token} setToken={setToken}/>}></Route>
//     </Routes> */}
//   </>
// )}


export default App;
