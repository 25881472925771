import React, { useState, useEffect } from 'react';

import axios from "axios"

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  LicenseInfo,
  jaJP,
} from '@mui/x-data-grid-pro';

import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

import CountBox from '../common/CountBox.js'

const theme = createTheme(
  jaJP,
);

export default function Analyize(props) {

  const { onClose, selectedCustomer, selectedCustomerCode ,open, startDate, endDate, token } = props;

  console.log(selectedCustomer);

  let columns;

  columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'sales_date',
      headerName: '伝票年月日',
      width: 130,
    },
    {
      field: 'customer',
      headerName: '得意先名',
      width: 250,
    },
    {
      field: 'product_code',
      headerName: '統一商品コード',
      width: 130,
    },
    {
      field: 'product_name',
      headerName: '商品名',
      width: 350,
    },
    {
      field: 'lot',
      headerName: 'ロットNo.',
      width: 100,
    },
    {
      field: 'sales_number',
      headerName: '販売数',
      width: 80,
      type: 'number'
    },
    {
      field: 'expiry_date',
      headerName: '期限',
      width: 130,
    },
    {
      field: 'deal_type',
      headerName: '売上区分',
      width: 130,
    },
  ];

  const [count, setCount] = React.useState();

  const [pageSize, setPageSize] = React.useState(10);
  const [gridRows, setGridRows] = useState([]);


  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASEURL;
    const periodGetURL = `${apiURL}/data_send/agency_sales_summary?customer_code=${selectedCustomerCode}&start_date=${startDate.format('YYYYMMDD')}&end_date=${endDate.format('YYYYMMDD')}`;

    const rows = new Array(0);

    axios({
      method: "GET",
      url:periodGetURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {

          // setBackdropOpen(false)

          setCount(response.data.count);

          console.log(response);
          response.data.rows.forEach((res) => {

            console.log(res['sales_date']);
            rows.push({
              id: res['id'],
              // end_date : startDate.format('YYYYMM') + ' - ' + endDate.format('YYYYMM'),
              sales_date: res['sales_date'],
              customer: res['customer_name'],
              customer_code: res['customer_code'],
              product_name: res['product_name'],
              product_code: res['product_code'],
              sales_number: res['sales_number'],
              lot: res['lot_number'],
              expiry_date: res['expiry_date'],
              deal_type: res['deal_type'],
            });
          });
          setGridRows(rows);
        }).catch((error) => {
          // setBackdropOpen(false)
          console.log('axios error')

          if (error.response) {
            // setAlertStatus({
            //   open: true,
            //   type: "error",
            //   message: `接続エラー(${error.response.status})`
            // });
          } else {
            // setAlertStatus({
            //   open: true,
            //   type: "error",
            //   message: `エラー(${error})`
            // });
          }
        }
        );


  }, [])


  const handleClose = () => {
    onClose('');
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={'xl'} fullWidth>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            {selectedCustomer}
          </Typography>
        </Toolbar>
      </AppBar>

      <CountBox>
        検索件数：{count}
      </CountBox>

      <ThemeProvider theme={theme}>
        <DataGridPro
          autoHeight
          rows={gridRows}
          columns={columns}
          density='compact'
          pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick={true}
          // onRowClick={(param) => handleRowClick(param.row)}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          // initialState={{ pinnedColumns: { left: ['brick_name'] } }}
        // componentsProps={{
        //   panel: {
        //     anchorEl: filterButtonEl,
        //   },
        //   toolbar: {
        //     setFilterButtonEl,
        //   },
        // }}
        // sortModel={sortModel}
        // onSortModelChange={(model) => setSortModel(model)}
        />
      </ThemeProvider>

    </Dialog>
  )
}