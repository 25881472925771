import axios from 'axios'
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import jwt_decode from 'jwt-decode';
import fileDownload from 'js-file-download'

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  LicenseInfo,
  jaJP,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import Alert from '@mui/material/Alert';

import Navbar from '../../components/layout/Navbar'

import HeadLine1 from '../common/HeadLine1.js'
import HeadLine2 from '../common/HeadLine2.js'
import PeriodText from '../common/PeriodText.js'
import StandardBox from '../common/StandardBox.js'
import CountBox from '../common/CountBox.js'
import ButtonStack from '../common/ButtonStack.js'
import SearchTextField from '../common/SearchTextField.js'
import BoxAroundTextField from '../common/BoxAroundTextField.js'
import MonthRangePicker from '../common/MonthRangePicker.js'
import SelectPrefecture from '../common/SelectPrefecture.js'
import CustomToolbar from '../common/CustomToolbar.js'
import StandardButton from '../common/StandardButton.js'
import ClearButton from '../common/ClearButton.js'
import PicSelect from '../common/PicSelect.js'
import getPrefectures from '../utility/getPrefectures.js'
import Analyze from './Analyze';

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE,
);

const theme = createTheme(
  jaJP,
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Hpgp(props) {


  const { token } = props;
  
  const navigate = useNavigate()  

  let columns;

  if (process.env.REACT_APP_COMPANY === 'amicus') {

    columns = [
      {
        field: 'id',
        hide: true,
      },
      // {
      // 	field: 'month',
      // 	headerName: '月',
      // 	width: 130,
      // },
      {
        field: 'brick_name',
        headerName: '病院名',
        width: 250,
      },
      {
        field: 'workplace_institution_code',
        headerName: 'DCF施設コード',
        width: 120,
      },
      {
        field: 'address',
        headerName: '住所',
        width: 350,
      },
      {
        field: 'doctor_name',
        headerName: '医師名',
        width: 130,
      },
      {
        field: 'department_name',
        headerName: '診療科',
        width: 130,
      },
      {
        field: 'total_sales_in_ethical_market',
        headerName: '総売上',
        width: 120,
        type: 'number'
      },
      {
        field: 'total_sales_in_selected_market',
        headerName: '分野売上',
        width: 120,
        type: 'number'
      },
      {
        field: 'vpriv',
        headerName: 'VPRIV',
        width: 120,
        type: 'number'
      },
      {
        field: 'vimizim',
        headerName: 'VIMIZIM',
        width: 120,
        type: 'number'
      },
      {
        field: 'cerdelga',
        headerName: 'cerdelga'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'aldurazyme',
        headerName: 'aldurazyme'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'brazaves',
        headerName: 'brazaves'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'cerezyme',
        headerName: 'cerezyme'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'elaprase',
        headerName: 'elaprase'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'fabrazyme',
        headerName: 'fabrazyme'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'myozyme',
        headerName: 'myozyme'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'naglazyme',
        headerName: 'naglazyme'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'replagal_dainippon',
        headerName: 'replagal_dainippon'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'replagal_takeda',
        headerName: 'replagal_takeda'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'galafold',
        headerName: 'galafold'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'agalsida_be_bs_jcr',
        headerName: 'agalsida_be_bs_jcr'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'agalsida_be_bs_p_s',
        headerName: 'agalsida_be_bs_p_s'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'izcargo',
        headerName: 'izcargo'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'hunterase_icv',
        headerName: 'hunterase_icv'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'nexviazyme',
        headerName: 'nexviazyme'.toUpperCase(),
        width: 120,
        type: 'number'
      },
      {
        field: 'employee_name',
        headerName: '担当者',
        width: 120,
      },
      {
        field: 'employee_code',
        headerName: '社員コード',
        width: 120,
      },
    ];
  } else {
    
    columns = [
      {
        field: 'id',
        hide: true,
      },
      // {
      // 	field: 'month',
      // 	headerName: '月',
      // 	width: 130,
      // },
      {
        field: 'brick_name',
        headerName: '病院名',
        width: 250,
      },
      {
        field: 'address',
        headerName: '住所',
        width: 350,
      },
      {
        field: 'max_beds',
        headerName: '病床数',
        width: 100,
        type: 'number',
      },
      {
        field: 'doctor_name',
        headerName: '医師名',
        width: 130,
      },
      {
        field: 'department_name',
        headerName: '診療科',
        width: 130,
      },
      {
        field: 'total_sales_in_ethical_market',
        headerName: '総売上',
        width: 120,
        type: 'number'
      },
      {
        field: 'total_sales_in_selected_market',
        headerName: '分野売上',
        width: 120,
        type: 'number'
      },
      {
        field: 'vpriv',
        headerName: '商品A',
        width: 120,
        type: 'number'
      },
      {
        field: 'vimizim',
        headerName: '商品B',
        width: 120,
        type: 'number'
      },
      {
        field: 'cerdelga',
        headerName: '商品C',
        width: 120,
        type: 'number'
      },
      {
        field: 'aldurazyme',
        headerName: '商品D',
        width: 120,
        type: 'number'
      },
      {
        field: 'brazaves',
        headerName: '商品E',
        width: 120,
        type: 'number'
      },
      {
        field: 'cerezyme',
        headerName: '商品F',
        width: 120,
        type: 'number'
      },
      {
        field: 'elaprase',
        headerName: '商品G',
        width: 120,
        type: 'number'
      },
      {
        field: 'fabrazyme',
        headerName: '商品H',
        width: 120,
        type: 'number'
      },
      {
        field: 'myozyme',
        headerName: '商品I',
        width: 120,
        type: 'number'
      },
      {
        field: 'naglazyme',
        headerName: '商品J',
        width: 120,
        type: 'number'
      },
      {
        field: 'replagal_dainippon',
        headerName: '競合商品A',
        width: 120,
        type: 'number'
      },
      {
        field: 'replagal_takeda',
        headerName: '競合商品B',
        width: 120,
        type: 'number'
      },
      {
        field: 'galafold',
        headerName: '競合商品C',
        width: 120,
        type: 'number'
      },
      {
        field: 'agalsida_be_bs_jcr',
        headerName: '競合商品D',
        width: 120,
        type: 'number'
      },
      {
        field: 'agalsida_be_bs_p_s',
        headerName: '競合商品E',
        width: 120,
        type: 'number'
      },
    ]
  }

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    open: false,
    type: "success",
    message: ""
  });

  let savedStartMonthHpgp = localStorage.getItem("savedStartMonthHpgp")
  if (savedStartMonthHpgp == null) {
    savedStartMonthHpgp = ''
  }
  let savedEndMonthHpgp = localStorage.getItem("savedEndMonthHpgp")
  if (savedEndMonthHpgp == null) {
    savedEndMonthHpgp = ''
  }

  let savedDoctorHpgp = localStorage.getItem("savedDoctorHpgp")
  if (savedDoctorHpgp == null) {
    savedDoctorHpgp = ''
  }
  let savedDoctorCodeHpgp = localStorage.getItem("savedDoctorCodeHpgp")
  if (savedDoctorCodeHpgp == null) {
    savedDoctorCodeHpgp = ''
  }
  let savedHospitalHpgp = localStorage.getItem("savedHospitalHpgp")
  if (savedHospitalHpgp == null) {
    savedHospitalHpgp = ''
  }
  let savedInstitutionCodeHpgp = localStorage.getItem("savedInstitutionCodeHpgp")
  if (savedInstitutionCodeHpgp == null) {
    savedInstitutionCodeHpgp = ''
  }
  let savedMinBeds = localStorage.getItem("savedMinBeds")
  if (savedMinBeds == null) {
    savedMinBeds = ''
  }
  let savedMaxBeds = localStorage.getItem("savedMaxBeds")
  if (savedMaxBeds == null) {
    savedMaxBeds = ''
  }
  let savedPrefectures = localStorage.getItem("savedPrefectures")
  if (savedPrefectures == null) {
    savedPrefectures = []
  } else {
    savedPrefectures = localStorage.getItem("savedPrefectures").split(',')
  }

  const [count, setCount] = React.useState();
  const [selectedPrefectures, setSelectedPrefectures] = React.useState(savedPrefectures);
  const [startMonth, setStartMonth] = React.useState(dayjs(savedStartMonthHpgp));
  const [endMonth, setEndMonth] = React.useState(dayjs(savedEndMonthHpgp));
  const [startDataMonth, setStartDataMonth] = React.useState(new Date());
  const [endDataMonth, setEndDataMonth] = React.useState(new Date());
  const [startEnabledMonth, setStartEnabledMonth] = React.useState(new Date());
  const [endEnabledMonth, setEndEnabledMonth] = React.useState(new Date());
  const [hospital, setHospital] = React.useState(savedHospitalHpgp);
  const [institutionCode, setInstitutionCode] = React.useState(savedInstitutionCodeHpgp);
  const [doctor, setDoctor] = React.useState(savedDoctorHpgp)
  const [doctorCode, setDoctorCode] = React.useState(savedDoctorCodeHpgp);
  const [prefectureOpen, setPrefectureOpen] = React.useState(false);
  const [analyzeOpen, setAnalyzeOpen] = React.useState(false);
  const [analyzeBrickName, setAnalyzeBrickName] = React.useState(false);
  const [analyzeMode, setAnalyzeMode] = React.useState('');
  const [minBeds, setMinBeds] = React.useState(savedMinBeds);
  const [maxBeds, setMaxBeds] = React.useState(savedMaxBeds);
  const [employeeCode, setEmployeeCode] = React.useState(jwt_decode(token).employee_code);

  const [pageSize, setPageSize] = React.useState(10);
  const [gridRows, setGridRows] = useState([]);



  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handlePrefecturesChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)

    if (value[0] === '') {
      value.shift();
    }

    // console.log(value)

    localStorage.setItem('savedPrefectures', value);

    setSelectedPrefectures(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleHospitalChange = (event) => {
    const {
      target: { value },
    } = event;

    // console.log(value)
    localStorage.setItem('savedHospitalHpgp', value);

    setHospital(value);
  };

  const handleInstitutionCodeChange = (event) => {
    const {
      target: { value },
    } = event;

    localStorage.setItem('savedInstitutionCodeHpgp', value);

    // console.log(value)

    setInstitutionCode(value);
  };

  const handleDoctorChange = (event) => {
    const {
      target: { value },
    } = event;

    localStorage.setItem('savedDoctorHpgp', value);
    // console.log(value)

    setDoctor(value);
  };

  const handleDoctorCodeChange = (event) => {
    const {
      target: { value },
    } = event;

    localStorage.setItem('savedDoctorCodeHpgp', value);

    // console.log(value)

    setDoctorCode(value);
  };

  const handleMinBedsChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedMinBeds', value);

    // console.log(value)

    setMinBeds(value);
  };

  const handleMaxBedsChange = (event) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem('savedMaxBeds', value);

    // console.log(value)

    setMaxBeds(value);
  };

  const handlePrefectureClose = (prefectures) => {
    setPrefectureOpen(false);

    if (prefectures.length !== 0) {
      setSelectedPrefectures(
        typeof prefectures === 'string' ? prefectures.split(',') : prefectures,
      );
      localStorage.setItem('savedPrefectures', prefectures);
    }
  };

  const handleRowClick = (params) => {

    if (process.env.REACT_APP_COMPANY === 'sparks') {
      setAnalyzeMode('hospital')
      setAnalyzeBrickName(params.brick_name)
      setAnalyzeOpen(true);
    }
  }  

  const handleAnalyzeClose = () => {
    setAnalyzeOpen(false);
    setAnalyzeBrickName('');
  };

  const handleEmployeeCode = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value);
    setEmployeeCode(value);
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_COMPANY);
    if(process.env.REACT_APP_COMPANY === 'amicus') {
      document.title = 'D.search ｜ IMSデータ検索';
    } else {
      document.title = 'D.search ｜ 病院売上検索';
    }
  }, []);


  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASEURL;
    const periodGetURL = `${apiURL}/data_send/hpgp_period`;

    axios.get(periodGetURL)
      .then(
        response => {

          const tmpStartMonth = new Date(response.data.min_month)
          const tmpEndMonth = new Date(response.data.max_month)

          setStartDataMonth(tmpStartMonth)
          setEndDataMonth(tmpEndMonth)

          setStartEnabledMonth(tmpStartMonth)
          setEndEnabledMonth(tmpEndMonth)

        }
      );

  }, [])



  const clearCondition = () => {
    console.log('clear condition clicked');
    setDoctor('');
    setDoctorCode('');
    setSelectedPrefectures([]);
    setHospital('');
    setInstitutionCode('');
    console.log(startDataMonth);
    setGridRows([]);
    setCount(0);

    localStorage.setItem('savedPrefectures', []);
    localStorage.setItem('savedDoctorHpgp', '');
    localStorage.setItem('savedDoctorCodeHpgp', '');
    localStorage.setItem('savedHospitalHpgp', '');
    localStorage.setItem('savedInstitutionCodeHpgp', '');
  }


  const getData = () => {

    setAlertStatus({
      open: false,
      type: "close",
      message: ""
    });

    let prefectureParam = ''

    selectedPrefectures.forEach((pref) => {
      (
        prefectureParam = prefectureParam + pref + ','
      )
    })

    prefectureParam = prefectureParam.slice(0, -1)

    if (jwt_decode(token).role === '一般ユーザー' && jwt_decode(token).employee_code !== null) {
      setEmployeeCode(jwt_decode(token).employee_code);
    }


    const apiURL = process.env.REACT_APP_BASEURL;
    const getURL = `${apiURL}/data_send/hpgp?prefecture=${prefectureParam}&hospital=${hospital}&workplace_institution_code=${institutionCode}&doctor=${doctor}&doctor_code=${doctorCode}&start_month=${startMonth.format('YYYYMM')}&end_month=${endMonth.format('YYYYMM')}&min_beds=${minBeds}&max_beds=${maxBeds}&employee_code=${employeeCode}`;

    const rows = new Array(0);

    setBackdropOpen(true);

    axios({
      method: "GET",
      url:getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {

          setBackdropOpen(false)

          setCount(response.data.count);

          console.log(response);
          response.data.rows.forEach((res) => {

            let employee_name;
            if (res['last_name'] === null && res['first_name'] === null) {
              employee_name = ''
            } else {
              employee_name = res['last_name'] + res['first_name']
            }
            rows.push({
              id: res['id'],
              // month : startMonth.format('YYYYMM') + ' - ' + endMonth.format('YYYYMM'),workplace_institution_code
              address: res['address_name'],
              brick_name: res['brick_name'],
              workplace_institution_code: res['workplace_institution_code'],
              doctor_name: res['doctor_name'],
              department_name: res['department_name'],
              max_beds: res['max_beds'],
              total_sales_in_ethical_market: res['total_sales_in_ethical_market'],
              total_sales_in_selected_market: res['total_sales_in_selected_market'],
              vpriv: res['vpriv'],
              vimizim: res['vimizim'],
              cerdelga: res['cerdelga'],
              aldurazyme: res['aldurazyme'],
              brazaves: res['brazaves'],
              cerezyme: res['cerezyme'],
              elaprase: res['elaprase'],
              fabrazyme: res['fabrazyme'],
              myozyme: res['myozyme'],
              naglazyme: res['naglazyme'],
              replagal_dainippon: res['replagal_dainippon'],
              replagal_takeda: res['replagal_takeda'],
              galafold: res['galafold'],
              agalsida_be_bs_jcr: res['agalsida_be_bs_jcr'],
              agalsida_be_bs_p_s: res['agalsida_be_bs_p_s'],
              izcargo: res['izcargo'],
              hunterase_icv: res['hunterase_icv'],
              nexviazyme: res['nexviazyme'],
              employee_name: employee_name,
              employee_code: res['employee_code'],
            });
          });
          setGridRows(rows);
        }).catch((error) => {
          setBackdropOpen(false)
          console.log('axios error')

          if (error.response) {
            setAlertStatus({
              open: true,
              type: "error",
              message: `接続エラー(${error.response.status})`
            });
          } else {
            setAlertStatus({
              open: true,
              type: "error",
              message: `エラー(${error})`
            });
          }
        }
        );

  }

  const dailyDownload = (downloadType) => {

    setBackdropOpen(true);

    let prefectureParam = ''

    selectedPrefectures.forEach((pref) => {
      (
        prefectureParam = prefectureParam + pref + ','
      )
    })

    prefectureParam = prefectureParam.slice(0, -1)

    const apiURL = process.env.REACT_APP_BASEURL;
    // const getURL = `${apiURL}/data_send/dcf/${downloadType}_download?prefecture=${prefectureParam}&doctor=${doctor}&doctor_code=${doctorCode}&hospital=${hospital}&department=${department}&society=${society}&specialty=${specialty}&min_beds=${minBeds}&max_beds=${maxBeds}&alma_mater=${almaMater}`;
    // const getURL = `${apiURL}/data_send/agency/${downloadType}_download?prefecture=${prefectureParam}${urlParameter}`;
    // const getURL = `${apiURL}/data_send/agency_sales_daily_excel?prefecture=${prefectureParam}&customer_name=${customer}&product_name=${product}&product_code=${productCode}&lot_number=${lot}&start_date=${startDate.format('YYYYMMDD')}&end_date=${endDate.format('YYYYMMDD')}&employee_code=${employeeCode}&dcf_name=${dcfName}`;
    const getURL = `${apiURL}/data_send/hpgp_daily_excel?prefecture=${prefectureParam}&hospital=${hospital}&workplace_institution_code=${institutionCode}&doctor=${doctor}&doctor_code=${doctorCode}&start_month=${startMonth.format('YYYYMM')}&end_month=${endMonth.format('YYYYMM')}&min_beds=${minBeds}&max_beds=${maxBeds}&employee_code=${employeeCode}`;

    console.log(getURL);

    axios({
      method: "GET",
      responseType: 'blob',
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    // axios.get(getURL, {
    //   responseType: 'blob',
    //   Authorization: 'Bearer ' + token
    //   // headers: {
    //   // }
    // })
      .then((res) => {
        console.log(res)
        const now = new Date();
        console.log('dsearch_' + now.toLocaleString("ja"))
        let fileName;
        if (downloadType === 'csv') {
          fileName = 'dsearch_sdc_dayly_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.csv';
        } else if (downloadType === 'excel') {
          fileName = 'dsearch_sdc_daily_' + now.toLocaleString("ja").replace(' ', '_').replace('/', '_').replace(':', '_') + '.xlsx';
        }
        fileDownload(res.data, fileName);
        setBackdropOpen(false);
      })
  }

  const dailyExcelDownload = () => {
    dailyDownload('excel');
  }


  return (
    <Box>
      <Navbar {...props}>
        {alertStatus.open &&
          <Alert
            onClose={() => {
              setAlertStatus({
                open: false,
                type: "close",
                message: ""
              });
            }}
            severity={alertStatus.type}>{alertStatus.message}</Alert>
        }

        <StandardBox>
          {(process.env.REACT_APP_COMPANY === 'amicus') &&
            <HeadLine1>
            IMSデータ検索
            </HeadLine1>
          }
          {(process.env.REACT_APP_COMPANY === 'sparks') &&
            <HeadLine1>
            病院売上検索
            </HeadLine1>
          }
        </StandardBox>

        {(jwt_decode(token).role !== '一般ユーザー') &&
          <PicSelect
            token={token}
            employeeCode={employeeCode}
            handleEmployeeCode={handleEmployeeCode}
          />
        }

        <StandardBox>
          <Stack
            sx={{
              // pt:1,
              pb: 2
            }}
          >
            <HeadLine2>
              ■ 期間
              {/* ■ 期間 */}
            </HeadLine2>

            <PeriodText>
              {/* {startDataMonth.getFullYear()} */}
              {startDataMonth.getFullYear()}年{startDataMonth.getMonth() + 1}月 - {endDataMonth.getFullYear()}年{endDataMonth.getMonth() + 1}月集計済み
            </PeriodText>
          </Stack>

          <BoxAroundTextField>

            <MonthRangePicker
              value={startMonth}
              // minDate={new Date("2021-01-01")}
              minDate={startEnabledMonth}
              maxDate={endEnabledMonth}
              onChange={(newValue) => {
                localStorage.setItem('savedStartMonthHpgp', newValue);
                setStartMonth(newValue);
              }}
            />
            ～
            <MonthRangePicker
              value={endMonth}
              minDate={startEnabledMonth}
              maxDate={endEnabledMonth}
              onChange={(newValue) => {
                localStorage.setItem('savedEndMonthHpgp', newValue);
                setEndMonth(newValue);
              }}
            />

          </BoxAroundTextField>
        </StandardBox>


        <StandardBox>
          <HeadLine2>
            ■ ワード
          </HeadLine2>
          <BoxAroundTextField>
            <SearchTextField
              id="hospital"
              label="施設名"
              value={hospital}
              onChange={handleHospitalChange}
            />
            <SearchTextField
              id="workplace_institution_code"
              label="施設コード"
              value={institutionCode}
              onChange={handleInstitutionCodeChange}
            />
            <SearchTextField
              id="doctor"
              label="医師名"
              onChange={handleDoctorChange}
              value={doctor}
            />
            <SearchTextField
              id="doctorid"
              label="医師コード"
              value={doctorCode}
              onChange={handleDoctorCodeChange}
            />
          </BoxAroundTextField>
        </StandardBox>

        {(process.env.REACT_APP_COMPANY === 'sparks') &&
          <>
            <StandardBox>
              <HeadLine2>
                ■ 病床数から探す
              </HeadLine2>
              <BoxAroundTextField>
                <SearchTextField
                  id="doctor"
                  label="最小数"
                  value={minBeds}
                  type="number"
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={handleMinBedsChange}
                />
                ～
                <SearchTextField
                  id="doctorid"
                  label="最大数"
                  value={maxBeds}
                  type="number"
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={handleMaxBedsChange}
                />
              </BoxAroundTextField>
            </StandardBox>
          </>
        }

        <StandardBox>
          <HeadLine2>
            ■ 地域から探す
          </HeadLine2>

          <BoxAroundTextField>

            <StandardButton
              onClick={() => {
                setAnalyzeMode('hospital')
                setPrefectureOpen(true);
              }}
              sx={{
                mt: 1,
              }}
            >
              地図から選択する
            </StandardButton>

            <FormControl
              sx={{
                width: 300,
                backgroundColor: "#FFFFFF",
                ml: 1
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">都道府県</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedPrefectures}
                onChange={handlePrefecturesChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {getPrefectures().map((name) => (
                  <MenuItem key={name['jis_country_code']} value={name['country_name']}>
                    <Checkbox checked={selectedPrefectures.indexOf(name['country_name']) > -1} />
                    <ListItemText primary={name['country_name']} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </BoxAroundTextField>
        </StandardBox>

        <StandardBox>
          <ButtonStack>
            <StandardButton onClick={getData}>検索</StandardButton>
            <ClearButton onClick={clearCondition}>条件クリア</ClearButton>
          </ButtonStack>
        </StandardBox>

        <CountBox>
          売上期間：{startMonth.format('YYYY年MM月')} ～ {endMonth.format('YYYY年MM月')}
        </CountBox>

        <CountBox>
        <CountBox>
          検索件数：{count}　月毎データダウンロード　<Button variant="outlined" onClick={dailyExcelDownload}>Excel</Button>
        </CountBox>
        </CountBox>

        <ThemeProvider theme={theme}>
          <DataGridPro
            autoHeight
            rows={gridRows}
            columns={columns}
            density='compact'
            pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick={true}
            onRowClick={(param) => handleRowClick(param.row)}
            // density='compact'
            components={{
              Toolbar: CustomToolbar,
            }}
            initialState={{ pinnedColumns: { left: ['brick_name'] } }}
          // componentsProps={{
          //   panel: {
          //     anchorEl: filterButtonEl,
          //   },
          //   toolbar: {
          //     setFilterButtonEl,
          //   },
          // }}
          // sortModel={sortModel}
          // onSortModelChange={(model) => setSortModel(model)}
          />
        </ThemeProvider>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
          onClick={handleBackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {(process.env.REACT_APP_COMPANY === 'sparks') &&
          <>
            <StandardBox>
              <BoxAroundTextField>
                <StandardButton
                  onClick={() => {
                    setAnalyzeOpen(true);
                    setAnalyzeMode('prefecture')
                    setAnalyzeBrickName("")
                  }}
                  sx={{
                    mt: 1,
                  }}
                >
                  薬ごと売上推移
                </StandardButton>
              </BoxAroundTextField>
            </StandardBox>
          </>
        }

      </Navbar>


      {analyzeOpen &&
        <Analyze
          selectedHospital={analyzeBrickName}
          open={analyzeOpen}
          onClose={handleAnalyzeClose}
          mode={analyzeMode}
          startMonth={startMonth}
          endMonth={endMonth}
          token={token}
        />
      }

      <SelectPrefecture
        setSelectedPrefectures={setSelectedPrefectures}
        selectedPrefectures={selectedPrefectures}
        open={prefectureOpen}
        onClose={handlePrefectureClose}
      />

    </Box>
  )
}