import {useState} from 'react'

import axios from 'axios'

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

export default function EachUpload(props) {

  // console.log(props.fileType);

  const apiUrl = process.env.REACT_APP_BASEURL;

  let title = '';
  let postUrl = '';

  if (props.fileType === 'dcf') {
    title = 'DCFファイルアップロード';
    postUrl = `${apiUrl}/data_import/dcf`;
  } else if (props.fileType === 'ims') {
    title = 'IMSファイルアップロード';
    postUrl = `${apiUrl}/data_import/hpgp`;
  } else if (props.fileType === 'sdc') {
    title = 'SDCファイルアップロード';
    postUrl = `${apiUrl}/data_import/sdc`;
  }

  const [file, setFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    open: false,
    type: "success",
    message: ""
  });


  const handleSetfile = (event) => {
    const { files } = event.target;
    console.log(files[0]['name']);

    setIsFilePicked(true)
    setFile(files[0])
  }

  // const upLoadFile = () => {
  //   if (props.fileType === 'dcf') {
  //     uploadDcfFile();
  //   } else if (props.fileType === 'hpgp') {
  //     uploadHptpFile();
  //   }
  
  // }

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const uploadFile = () => {
  
    setAlertStatus({
      open: false,
      type: "close",
      message: ""
    });

    setBackdropOpen(true);

    const formData = new FormData();

    formData.append("file", file);

    console.log('upload button pushed')

    axios({
      url: `${postUrl}`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': "multipart/form-data"
      }
    }).then((response) => {
      setBackdropOpen(false)
      setAlertStatus({
        open: true,
        type: "success",
        message: `${props.fileType}ファイルが正常にアップロードされました`
      });
    console.log(response.data);
      // setIsFilePicked(false);
      // setFile(null);
    }).catch((error) => {

      setBackdropOpen(false)

      if (error.response) {
        setAlertStatus({
          open: true,
          type: "error",
          message: `接続エラー(${error.response.status})`
        });
      } else {
        setAlertStatus({
          open: true,
          type: "error",
          message: `エラー(${error})`
        });
      }

      console.log('errro')
      console.log(error)
    })
  }

  return(
    <div>
      {alertStatus.open &&
        <Alert
          onClose={() => {
            setAlertStatus({
              open: false,
              type: "close",
              message: ""
            });
          }}
          severity={alertStatus.type}>{alertStatus.message}</Alert>
      }

      <Typography variant="h4">{title}</Typography>
      <Button
        variant="contained"
        component="label"
      >
        ファイル選択
        <input
          type="file"
          hidden
          onChange={handleSetfile}
        />
      </Button>
      
      {isFilePicked ? (
        <div>
          <p>ファイル名: {file.name}</p>
          <p>Filetype: {file.type}</p>
          <p>Size in bytes: {file.size}</p>
          <p>file
            lastModifiedDate:{' '}
            {file.lastModifiedDate.toLocaleDateString()}
          </p>
        </div>
      ) : (
        <p>Select a file to show details</p>
      )}
      <Button
        variant="contained"
        component="label"
        disabled={!isFilePicked}
        onClick={uploadFile}
        // disabled
      >
        アップロード
      </Button>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
          onClick={handleBackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  )
}