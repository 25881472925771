import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CustomedTextField = styled(TextField)({
  marginRight: 8,
  backgroundColor: "#FFFFFF"
});

export default function SearchTextBox(props) {

  return(
    <CustomedTextField
      {...props}
      variant="outlined"
    >
      {props.children}
    </CustomedTextField>
  )
}
