import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';

import LinkIcon from '@mui/icons-material/Link';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import axios from 'axios'

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function DoctorSummary(props) {
  const { onClose, personal_code, open, token } = props;

  const [doctorRows, setDoctorRows] = useState([]);
  const [doctorName, setDoctorName] = useState('');
  const [doctorNameKana, setDoctorNameKana] = useState('');
  const [doctorSociety, setDoctorSociety] = useState([]);
  const [doctorGraduationYear, setDoctorGraduationYear] = useState([]);
  const [doctorAlmaMater, setDoctorAlmaMater] = useState([]);

  const handleClose = () => {
    onClose('');
  };

  const handleDoctorOnClick = (formal_name) => {
    console.log(formal_name);
    onClose(formal_name);
  }

  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASEURL;
    const getURL = `${apiURL}/data_send/dcf/doctor/${personal_code}`;

    let tmpSociety = [];

    axios({
      method: "GET",
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {

          console.log(response);
          setDoctorRows(response.data.doctor);
          setDoctorName(response.data.doctor[0].doctor_name)
          console.log(response.data.doctor[0].doctor_name)
          setDoctorNameKana(response.data.doctor[0].doctor_name_kana)
          setDoctorGraduationYear(response.data.doctor[0].graduation_year)
          setDoctorAlmaMater(response.data.doctor[0].alma_mater_name)

          response.data.doctor[0].m_association_name.split(',').forEach((society) => {
            tmpSociety.push(society)
          })
          setDoctorSociety(tmpSociety);
        }
      );

  }, [personal_code])

  return (
    <Dialog onClose={handleClose} open={open}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            {doctorName} ({doctorNameKana})
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        // sx={{
        //   margin: 5
        // }}
      >
        <Box
          sx={{
            padding: 3,
            pb: 1,
            m: 2,
            mt: 0,
            mb: 0,
            // border: 1
          }}
          
        >
          <Typography variant="h5">出身大学</Typography>
          <Box sx={{m: 1}}>
              {doctorAlmaMater}
          </Box>
          <Typography variant="h5">卒業年度</Typography>
            <Box sx={{m: 1}}>
              {doctorGraduationYear}
          </Box>
          <Typography variant="h5">所属病院</Typography>

          <List sx={{ pt: 1 }}>
            {doctorRows.map((doctor) => (
              <ListItem onClick={() => handleDoctorOnClick(doctor.workplace_institution_code)} key={doctor.workplace_institution_code}>
                {/* <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon> */}
                {/* <ListItemText primary={doctor.formal_name + '(病床数 ' + doctor.max_beds + ')'} secondary={doctor.department_name}/> */}
                <ListItemText primary={`${doctor.formal_name} (病床数 ${doctor.max_beds})`} secondary={doctor.department_name}/>
                <Button variant='contained' sx={{ml: 2}}>詳細</Button>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            padding: 3,
            pb: 1,
            m: 2,
            mt: 0,
            mb: 0,
            // border: 1
          }}
        >
          <Typography variant="h5">所属学会</Typography>
          <List sx={{ pt: 1 }}>
            {doctorSociety.map((society) => (
              <ListItem>
                <ListItemText primary={society} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Dialog>
  );
}

DoctorSummary.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  personal_code: PropTypes.string.isRequired,
};
