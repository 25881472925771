import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const CustomedTypography = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 19.2,
  // color: 'text.primary',
  color: "#286eb7",
  letterSpacing: ''
});

export default function HeadLine2(props) {
  return(
    <CustomedTypography>{props.children}</CustomedTypography>
  )
}