import { useEffect, useState } from "react"

import axios from "axios"

import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import {
  DataGridPro,
} from '@mui/x-data-grid-pro';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SelectPrefecture2 from '../../components/common/SelectPrefecture2'
import ProfitGraph from './ProfitGraph'
import getPrefectures from "../utility/getPrefectures"
import medicineNameTable from "../utility/medicineNameTable"


const colorList = [
  "#E60012",
  "#EB6100",
  "#ef5350",
  "#F39800",
  "#FCC800",
  "#FFF100",
  "#CFDB00",
  "#8FC31F",
  "#22AC38",
  "#009944",
  "#009B6B",
  "#009E96",
  "#00A0C1",
  "#00A0E9",
  "#0086D1",
  "#0068B7",
  "#00479D",
  "#1D2088",
  "#E60012",
  "#00A0E9",
];


export default function Analyize(props) {

  const { onClose, selectedHospital, open, mode, startMonth, endMonth, token } = props;

  let savedPrefecture = localStorage.getItem("savedPrefecture")
  if (savedPrefecture == null) {
    savedPrefecture = '全国'
  }

  const [medicineList, setMedicineList] = useState([]);
  const [allMedicineList, setAllMedicineList] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [gridRows, setGridRows] = useState([]);
  const [graphRows, setGraphRows] = useState([]);
  const [rowHide, setRowHide] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);
  const [colorTable, setColorTable] = useState({});
  const [graphTypeTable, setGraphTypeTable] = useState({});

  const [selectedPrefecture, setSelectedPrefecture] = useState(savedPrefecture);

  useEffect(() => {
    let monthList = new Array(0);
    for (let i = new Date(startMonth); i < endMonth;) {

      const dateString = String(i.getFullYear()) + String(i.getMonth() + 1).padStart(2, "0");
      // console.log(dateString);
      monthList.push(dateString)

      i.setMonth(i.getMonth() + 1)
    }

    // const endMonthTmp = new Date(endMonth);
    // monthList.push(String(endMonthTmp.getFullYear()) + String(endMonthTmp.getMonth() + 1).padStart(2, "0"))

    // console.log(monthList);

    let tmpColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 50,
        hide: true
      },
      {
        field: 'medicine',
        headerName: '薬名',
        width: 180,
      },
    ];

    monthList.forEach(month => {
      tmpColumns.push(
        {
          field: month,
          headerName: month,
          width: 120,
          type: 'number'
        }
      )
      // console.log(month);
    })

    setColumns(tmpColumns);
  }, [selectedPrefecture, startMonth, endMonth,]);


  useEffect(() => {
    if (selectedHospital !== "") {
      setTitle(selectedHospital);
    } else {
      setTitle(selectedPrefecture);
    }
  }, [selectedPrefecture]);

  useEffect(() => {

    // console.log(selectedPrefecture);

    let getURL;

    const apiURL = process.env.REACT_APP_BASEURL;
    const baseURL = `${apiURL}/data_send/hpgp_analyze?start_month=${startMonth.format('YYYYMM')}&end_month=${endMonth.format('YYYYMM')}`
    // const baseURL = `${apiURL}/data_send/hpgp_analyze?start_month=202207&end_month=202212&`
    if (mode === 'hospital') {
      getURL = `${baseURL}&prefecture=&hospital=${selectedHospital}`;
    } else {
      getURL = `${baseURL}&prefecture=${selectedPrefecture}&hospital=`;
    }
    // console.log(getURL);
    const rows = new Array(0);
    const tmpRowHide = new Array(0);

    // setBackdropOpen(true);


    axios({
      method: "GET",
      url: getURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {
          
          let medicines = [];

          // 無理やり薬名変更
          Object.keys(response.data.rows).forEach(row => {
            if (medicineNameTable[row] !== undefined) {
              medicines.push(medicineNameTable[row]);
            } else {
              medicines.push(row);
            }
          })

          // 無理やり薬名変更ここまで

          // これが本来
          // medicines = Object.keys(response.data.rows);

          setMedicineList(medicines);
          setAllMedicineList(medicines);

          getPrefectures().forEach(row => {
            if (medicines.includes(row.country_name)) {
              setSelectedPrefecture(row.country_name);
            }
          })

          const responseRows = {};

          // 無理やり薬名変更

          const responseRowsTmp = response.data.rows;
          const responseRowsKeys = Object.keys(responseRowsTmp);

          responseRowsKeys.forEach(row => {
            if (medicineNameTable[row] !== undefined) {
              responseRows[medicineNameTable[row]] = responseRowsTmp[row];

            } else {
              responseRows[row] = responseRowsTmp[row];
            }
          })
          // 無理やり薬名変更ここまで

          // これが本来
          // const responseRows = response.data.rows;

          // console.log(responseRows);

          medicines.forEach(medicine => {
            const row = {}

            row['medicine'] = medicine;
            const profits = Object.keys(responseRows[medicine])
            profits.forEach(month => {
              row[month] = responseRows[medicine][month];
            })

            row['id'] = rows.length;
            rows.push(row);
            tmpRowHide[medicine] = true;
            // tmpRowHide.push({medicine: true})
          })

          // console.log(rows);
          // console.log(response.data.graph_medicine_rows);
          // console.log(response.data.graph_locale_rows);
          setRowHide(tmpRowHide);
          setGridRows(rows);
          setAllRows(rows);

          // console.log(response.data.graph_rows);

          // 無理やり薬名変更

          const tmpGraphRows = [];

          response.data.graph_rows.forEach(row => {
            const rowKeys = Object.keys(row);
            const tmpRow = {}
            rowKeys.forEach(item => {
              if (medicineNameTable[item] !== undefined) {
                tmpRow[medicineNameTable[item]] = row[item];
              } else {
                tmpRow[item] = row[item];
              }
            })
            tmpGraphRows.push(tmpRow);
          })
          console.log(tmpGraphRows);

          setGraphRows(tmpGraphRows)

          // 無理やり薬名変更ここまで

          // これが本来
          // const responseRows = response.data.rows;
          // setGraphRows(response.data.graph_rows)

        
        
          const tmpColorTable = {};
          const tmpGraphTypeTable = {};
      
          let i = 0;
      
          medicines.forEach(item => {
            tmpColorTable[item] = colorList[i];
            if (item !== "全国" && item !== selectedPrefecture && item !== selectedHospital ) {
              tmpGraphTypeTable[item] = "Line";
            } else {
              tmpGraphTypeTable[item] = "Bar";
            }
          })
      
          console.log(tmpColorTable);
          console.log(tmpGraphTypeTable);
      
          setGraphTypeTable(tmpColorTable);
          setColorTable(tmpColorTable);

        }).catch((error) => {
          // setBackdropOpen(false)
          console.log(error)

          if (error.response) {
            // setAlertStatus({
            //   open: true,
            //   type: "error",
            //   message: `接続エラー(${error.response.status})`
            // });
          } else {
            // setAlertStatus({
            //   open: true,
            //   type: "error",
            //   message: `エラー(${error})`
            // });
          }
        }
        );

  }, [selectedPrefecture, selectedHospital, startMonth, endMonth]);

  const handleCheckboxChange = (event) => {
    const {
      target: { id, checked },
    } = event;

    console.log(id);
    // console.log(checked);

    const tmpRowHide = rowHide;
    let tmpRows = new Array(0);

    console.log(medicineList);

    let tmpMedicineList;

    if (checked === false) {
      tmpRowHide[id] = false;
      tmpMedicineList = medicineList.filter(item => item !== id);
    } else if (checked === true) {
      tmpRowHide[id] = true;
      tmpMedicineList = medicineList;
      tmpMedicineList.push(id);
    }

    console.log(tmpMedicineList);

    setRowHide(tmpRowHide)

    allRows.forEach(row => {
      if (rowHide[row["medicine"]] === true) {
        tmpRows.push(row);
      }
    })

    let tmp2MedicineList = [];

    allMedicineList.forEach(item => {
      if (tmpMedicineList.includes(item)) {
        tmp2MedicineList.push(item);
      }
    })

    setMedicineList(tmp2MedicineList);

    setGridRows(tmpRows);
  }

  const handleClose = () => {
    onClose('');
  };

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Accordion defaultExpanded={true}>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{backgroundColor: "#e0e0e0"}}
        >
          <Typography variant="h5">グラフ項目選択</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup row={true}>
            {allRows.map((name) => (
              <>
                <FormControlLabel control={<Checkbox defaultChecked={true} onChange={handleCheckboxChange} id={name['medicine']} />} label={name['medicine']} />
              </>
            ))}
            {/* <FormControlLabel control={<Checkbox onChange={handleCheckboxChange} />}  /> */}
          </FormGroup>
          {(mode === "prefecture") &&
            <SelectPrefecture2
              setSelectedPrefecture={setSelectedPrefecture}
              selectedPrefecture={selectedPrefecture}
              savedPrefecture={savedPrefecture}
            />
          }
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{backgroundColor: "#e0e0e0"}}
        >
          <Typography variant="h5">グラフ表示</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <ProfitGraph
            rows={graphRows}
            rowHide={rowHide}
            medicineList={medicineList}
            prefecture={selectedPrefecture}
            hospital={selectedHospital}
            colorTable={colorTable}
            graphTypeTable={graphTypeTable}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{backgroundColor: "#e0e0e0"}}
        >
          <Typography variant="h5">テーブル表示</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <DataGridPro
            autoHeight
            rows={gridRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            // pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
            // disableVirtualization
          />
        </AccordionDetails>
      </Accordion>
    </Dialog>
  )
}