import React, { useState, useEffect } from 'react';

import axios from 'axios';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import HeadLine2 from '../common/HeadLine2.js'
import StandardBox from '../common/StandardBox.js'

export default function PicSelect(props) {

  const { token, employeeCode, handleEmployeeCode } = props;

  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    

    const apiURL = process.env.REACT_APP_BASEURL;
    const employeeListURL = `${apiURL}/data_send/employee_list`;

    let lists = []

    axios({
      method: "GET",
      url:employeeListURL,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(
        response => {

          response.data.rows.forEach((res) => {

            console.log(res);
            lists.push({
              employeeCode: res['employee_code'],
              employeeName: res['last_name'] + res['first_name'],
            })
          });

          setEmployeeList(lists);
        }
      );

  }, [token])


  return (

    <StandardBox>
      <HeadLine2>
        ■ 担当者
        {/* ■ 期間 */}
      </HeadLine2>

      <FormControl
        sx={{
          width: 200,
          backgroundColor: "#FFFFFF",
          // mb: 2
        }}
      >
        <InputLabel id="demo-multiple-checkbox-label">担当者</InputLabel>
        <Select
          labelId="prefecture"
          id="prefecture"
          label="都道府県名"
          value={employeeCode}
          onChange={handleEmployeeCode}
        // input={<OutlinedInput label="Tag" />}
        >
          <MenuItem key={''} value={''}>選択なし</MenuItem>
          {employeeList.map((employee) => (
            <MenuItem key={employee['employeeCode']} value={employee['employeeCode']}>
              {employee['employeeName']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </StandardBox>
  )
}