import React from 'react';
import { useEffect, useState } from "react"
import {
  CartesianGrid,
  Legend,
  Line,
  Bar,
  ComposedChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const colorList = [
  "#E60012",
  "#EB6100",
  "#ef5350",
  "#F39800",
  "#FCC800",
  "#FFF100",
  "#CFDB00",
  "#8FC31F",
  "#22AC38",
  "#009944",
  "#009B6B",
  "#009E96",
  "#00A0C1",
  "#00A0E9",
  "#0086D1",
  "#0068B7",
  "#00479D",
  "#1D2088",
  "#E60012",
  "#00A0E9",
]


// const colorTable = {
//   "vpriv": "#E60012",
//   "vimizim": "#EB6100",
//   "cerdelga": "#ef5350",
//   "aldurazyme": "#F39800",
//   "brazaves": "#FCC800",
//   "cerezyme": "#FFF100",
//   "elaprase": "#CFDB00",
//   "fabrazyme": "#8FC31F",
//   "myozyme": "#22AC38",
//   "naglazyme": "#009944",
//   "replagal_dainippon": "#009B6B",
//   "replagal_takeda": "#009E96",
//   "galafold": "#00A0C1",
//   "agalsida_be_bs_jcr": "#00A0E9",
//   "agalsida_be_bs_p_s": "#0086D1",
//   "izcargo": "#0068B7",
//   "hunterase_icv": "#00479D",
//   "nexviazyme": "#1D2088",
//   "全国": "#E60012",
//   "県": "#00A0E9",
// }

// const graphTypeTable = {
//   "vpriv": "Line",
//   "vimizim": "Line",
//   "cerdelga": "Line",
//   "aldurazyme": "Line",
//   "brazaves": "Line",
//   "cerezyme": "Line",
//   "elaprase": "Line",
//   "fabrazyme": "Line",
//   "myozyme": "Line",
//   "naglazyme": "Line",
//   "replagal_dainippon": "Line",
//   "replagal_takeda": "Line",
//   "galafold": "Line",
//   "agalsida_be_bs_jcr": "Line",
//   "agalsida_be_bs_p_s": "Line",
//   "izcargo": "Line",
//   "hunterase_icv": "Line",
//   "nexviazyme": "Line",
//   "全国": "Bar",
//   // "県": "Bar",
// }

const colorTable = {
  "商品A": "#E60012",
  "商品B": "#EB6100",
  "商品C": "#ef5350",
  "商品D": "#F39800",
  "商品E": "#FCC800",
  "商品F": "#FFF100",
  "商品G": "#CFDB00",
  "商品H": "#8FC31F",
  "商品I": "#22AC38",
  "商品J": "#009944",
  "競合商品A": "#009B6B",
  "競合商品B": "#009E96",
  "競合商品C": "#00A0C1",
  "競合商品D": "#00A0E9",
  "競合商品E": "#0086D1",
  "競合商品F": "#0068B7",
  "競合商品G": "#00479D",
  "競合商品H": "#1D2088",
  "全国": "#E60012",
  "県": "#00A0E9",
}

const graphTypeTable = {
  "商品A": "Line",
  "商品B": "Line",
  "商品C": "Line",
  "商品D": "Line",
  "商品E": "Line",
  "商品F": "Line",
  "商品G": "Line",
  "商品H": "Line",
  "商品I": "Line",
  "商品J": "Line",
  "競合商品A": "Line",
  "競合商品B": "Line",
  "競合商品C": "Line",
  "競合商品D": "Line",
  "競合商品E": "Line",
  "競合商品F": "Line",
  "競合商品G": "Line",
  "競合商品H": "Line",
  "全国": "Bar",
  // "県": "Bar",
}


export default function ProfitGraph(props) {

  const { medicineList, prefecture, hospital, rows} = props;
  // const { medicineList, prefecture, hospital, rows, colorTable, graphTypeTable } = props;
  // const { medicineList, prefecture, hospital, rows, colorTable } = props;

  console.log(medicineList);

  // const [colorTable, setColorTable] = useState({});
  // const [graphTypeTable, setGraphTypeTable] = useState({});

  // useEffect(() => {
  //   console.log('here1');

  //   const tmpColorTable = {};
  //   const tmpGraphTypeTable = {};

  //   let i = 0;

  //   medicineList.forEach(item => {
  //     tmpColorTable[item] = colorList[i];
  //     if (item !== "全国" && item !== prefecture && item !== hospital ) {
  //       tmpGraphTypeTable[item] = "Line";
  //     } else {
  //       tmpGraphTypeTable[item] = "Bar";
  //     }
  //   })

  //   console.log(tmpColorTable);
  //   console.log(tmpGraphTypeTable);

  //   setGraphTypeTable(tmpColorTable);
  //   setColorTable(tmpColorTable);
  // }, [medicineList, hospital, prefecture]);
  

  return (
    <>
      {/* {console.log(medicineList)} */}

      {/* {medicineList.map((medicine) => (
        {medicine}
      ))} */}



      <ComposedChart
        width={900}
        height={700}
        data={rows}
        margin={{
          top: 20,
          right: 50,
          left: 30,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis yAxisId={1} tickFormatter={value => `${value.toLocaleString()}`}/>
        <YAxis yAxisId={2} orientation="right" tickFormatter={value => `${value.toLocaleString()}`}/>

        {medicineList.map((medicine) => (
          graphTypeTable[medicine] === 'Line' &&
            <Line yAxisId={1} dataKey={medicine} stroke={colorTable[medicine]}/>
            // <Line yAxisId={1} dataKey={medicine} stroke={colorTable[medicine]}/>
        ))}

        {medicineList.includes('全国') &&
          <Bar yAxisId={2} dataKey="全国" barSize={20} fill="#00A0E9" />
        }
        {(medicineList.includes(props.prefecture) && props.prefecture !== '全国') &&
          <Bar yAxisId={2} dataKey={props.prefecture} barSize={20} fill="#E60012" />
        }

        {(medicineList.includes(props.hospital) && props.hospital !== '') &&
          <Bar yAxisId={2} dataKey={props.hospital} barSize={20} fill="#FF9800" />
        }

        <Legend />
        {/* <Tooltip cursor={{ fill: "transparent" }}/> */}
        {/* <Tooltip cursor={false}/> */}
        <Tooltip contentStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}/>
        {/* <Tooltip content={<CustomTooltip />} /> */}

      </ComposedChart>
    </>
  )
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  }

  return null;
};

// {props.medicineList.map((medicine) => (
//   <>
//     <Line yAxisId={1} dataKey={medicine} stroke="#E60012" />
//   </>
// ))}
