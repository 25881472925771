import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const CustomedBox = styled(Box)({
  marginBottom: 40,
});

export default function StandardBox(props) {
  return(
    <CustomedBox>{props.children}</CustomedBox>
  )
}